import { IFinoVersion } from '@fino-ui/common';

/**
 * Generated `version.ts` file.
 *
 * THIS CODE IS AUTOMATICALLY GENERATED.
 *
 * Generated by @fino-ui/cli@2.2.21.
 * To regenerate, run `npx fino-ui version`.
 */
export const Version: IFinoVersion = {
  version: '1.0.0',
  revision: 'c272a1d',
  branch: 'HEAD',
  env: 'development',
};
