<div>
  <span
    class="arrow arrow-up has-click"
    (click)="sortAsc()"
    [class.is-selected]="isSortAsc"
  ></span>
  <span
    class="arrow arrow-down has-click"
    (click)="sortDesc()"
    [class.is-selected]="isSortDesc"
  ></span>
</div>
