<div class="modal-content">
  <div class="modal-header" #handle>
    <h1 class="title" translate="captureTransaction.submitModal.title"></h1>
  </div>
  <div class="modal-body">
    <p translate="captureTransaction.submitModal.description"></p>
  </div>
  <div class="modal-footer">
    <fino-button-row>
      <button type="button" (click)="close()" fino-button>Zurück</button>
      <button
        [primary]="true"
        type="button"
        (click)="submit()"
        fino-button
        translate="captureTransaction.submitModal.submit"
      ></button>
    </fino-button-row>
  </div>
</div>
