import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgxModalService } from 'ngx-modalview';
import { ApproveModalComponent } from 'src/app/components/approve-modal/approve-modal.component';
import { ButtonModule } from '@fino-ui/button';

@Component({
  selector: 'app-unlock-transaction-list',
  templateUrl: './unlock-transaction-list.component.html',
  imports: [ButtonModule],
})
export class UnlockTransactionListComponent {
  @Output()
  public unlockList = new EventEmitter();

  @Input()
  isLoading: boolean = false;

  constructor(private ngxModalService: NgxModalService) {}
  openSubmitModal() {
    this.ngxModalService
      .addModal(ApproveModalComponent, {
        title: 'captureTransaction.unlock.title',
        description: 'captureTransaction.unlock.description',
      })
      .subscribe((approved) => {
        if (approved) this.unlockList.emit(true);
      });
  }
}
