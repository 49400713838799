@if (noJobLeft) {
  <div
    class="is-flex is-justify-content-center is-align-items-center is-fullheight"
  >
    <h5 class="title is-size-5" translate="captureTransaction.empty"></h5>
  </div>
} @else {
  @if (isPremiumBank) {
    <div class="mb-2 ml-5">
      <article class="message is-warning">
        <div class="message-body">
          <fa-icon class="has-text-warning" size="sm" [icon]="faStar"></fa-icon>
          <p
            translate="captureTransaction.premiumBank"
            [translateParams]="{ premiumDocUrl: premiumDocUrl }"
          ></p>
        </div>
      </article>
    </div>
  }

  <div class="columns">
    <div class="column is-9 is-8-widescreen darkmode-ignore">
      @if (rotatedDocUrl) {
        <div class="field">
          <label
            class="mr-2"
            translate="captureTransaction.originalDocument"
          ></label>
          <input
            id="switchExample"
            type="checkbox"
            name="switchExample"
            class="switch is-rounded"
            [checked]="useRotatedDocUrl"
            (click)="toggleRotatedDocUrl()"
          />
          <label for="switchExample" translate="captureTransaction.rotated">
          </label>
        </div>
      }
      @if (pdfUrl) {
        <ng2-pdfjs-viewer
          viewerId="JobPdfViewer"
          pagemode="thumbs"
          [pdfSrc]="pdfUrl"
          (onPageChange)="pdfViewerPageChange($event)"
        ></ng2-pdfjs-viewer>
      }
    </div>
    @if (job) {
      <div class="column">
        @if (isQualityAssurance) {
          <article class="message is-primary">
            <div
              class="message-body"
              translate="captureTransaction.capturedBy"
              [translateParams]="{ capturedBy: job.capturedBy }"
            ></div>
          </article>
        }
        @if (statusIsClosed) {
          <article class="message is-warning">
            <div class="message-body">Status: {{ job.status }}</div>
          </article>
        }

        <article
          class="message"
          [class.is-info]="!statusIsClosed"
          [class.is-warning]="statusIsClosed"
        >
          <div class="message-body">
            <p>
              Status {{ job.status }} by User {{ job.statusChangedBy }} ({{
                job.statusChangedAt | date: "short"
              }})
            </p>
            @if (isSMMUser) {
              <app-unlock-transaction-list
                (unlockList)="unlockBalanceList()"
                [isLoading]="!unlockBalanceListSubscription.closed"
              ></app-unlock-transaction-list>
            }
          </div>
        </article>
        @if (documentIsHidden) {
          <article class="message is-warning">
            <div
              class="message-body"
              translate="captureTransaction.hidden"
            ></div>
          </article>
        }

        @if (showIbanCheck && !isLocal) {
          <app-iban-check
            [newIban]="job.newIban"
            [oldIban]="job.oldIban"
            [id]="job.id"
            [previousJobId]="job.previousJobId"
            [isQualityAssurance]="isQualityAssurance"
            (checkSuccess)="ibanCheckSuccess($event)"
          ></app-iban-check>
        } @else {
          <div class="tabs is-toggle is-centered is-fullwidth navigation-tabs">
            <ul>
              @for (mode of modes | keyvalue; track mode.value) {
                <li
                  (click)="selectMode(mode.value)"
                  [class.is-active]="selectedMode === mode.value"
                >
                  <a>
                    <b [class.has-text-grey]="selectedMode !== mode.value">{{
                      mode.value + ".title" | translate
                    }}</b>
                    <div class="ml-2">
                      <div class="tags has-addons">
                        <span class="tag is-light">{{
                          countItemsBy(mode.value)
                        }}</span>
                        <span
                          class="tag"
                          [class.is-primary]="selectedMode !== mode.value"
                          [class.is-dark]="selectedMode === mode.value"
                          >{{ countItemsBy(mode.value, true) }}</span
                        >
                      </div>
                    </div>
                  </a>
                </li>
              }
            </ul>
          </div>

          @if (selectedMode === modes.DIRECT_DEBITS) {
            <app-direct-debits
              [form]="directDebitsForm"
              [directDebits]="directDebits"
              (directDebitsSubmitted)="directDebitsSubmitted($event)"
            ></app-direct-debits>
          } @else if (selectedMode === modes.RECIEVED_PAYMENTS) {
            <app-recieved-payments
              [form]="receivedPaymentsForm"
              [receivedPayments]="receivedPayments"
              (receivedPaymentsSubmitted)="receivedPaymentsSubmitted($event)"
            ></app-recieved-payments>
          } @else if (selectedMode === modes.STANDING_ORDERS) {
            <app-standing-orders
              [form]="standingOrdersForm"
              [standingOrders]="standingOrders"
              (standingOrdersSubmitted)="standingOrdersSubmitted($event)"
            ></app-standing-orders>
          }

          <div class="divider"><p translate="captureTransaction.menu"></p></div>

          <form [formGroup]="loadNextDocumentForm">
            <fino-checkbox
              formControlName="loadNext"
              label="captureTransaction.loadNext"
            >
            </fino-checkbox>
          </form>

          <div class="is-flex mt-2">
            @if (hasTransactions) {
              <button
                fino-button
                type="submit"
                [loading]="isSubmitLoading"
                [primary]="true"
                (click)="openSubmitModal()"
              >
                <label
                  class="mr-1"
                  translate="captureTransaction.submit"
                ></label>
              </button>
            }

            @if (hasTransactions && !isQualityAssurance) {
              <button
                fino-button
                [loading]="isSubmitLoading"
                (click)="openSubmitModalQA()"
                class="ml-2"
              >
                <label
                  class="mr-1"
                  translate="captureTransaction.submitToQA"
                ></label>
              </button>
            }

            @if (isQualityAssurance) {
              <button
                [loading]="isSubmitLoading"
                class="ml-2"
                fino-button
                (click)="sendToAmetras()"
              >
                <label
                  class="mr-1"
                  translate="captureTransaction.captureAgain"
                ></label>
              </button>
            }
          </div>
        }

        @if (!isQualityAssurance && !isSMMUser) {
          <article class="mt-5 panel" [formGroup]="reportForm">
            <p
              class="panel-heading has-text-white"
              translate="captureTransaction.detailCheck"
            ></p>
            <div class="panel-block is-flex-direction-column">
              <div class="is-fullwidth mb-2">
                <fino-select
                  [items]="reportPresetItems"
                  formControlName="preset"
                  label="preset"
                  bindLabel="label"
                  bindValue="value"
                >
                  <div *select-item="let item">
                    <div>{{ item.value | translate }}</div>
                  </div>
                </fino-select>
              </div>

              <p translate="captureTransaction.reportDescription"></p>
              <div class="mt-2 is-fullwidth">
                <fino-textarea formControlName="comment" label="comment">
                </fino-textarea>
              </div>
              <div class="mt-2 is-justify-content-flex-start is-fullwidth">
                <button
                  [loading]="reportDocumentLoading"
                  fino-button
                  (click)="reportDocument()"
                >
                  <label
                    class="mr-1"
                    translate="captureTransaction.report"
                  ></label>
                </button>
              </div>
            </div>
          </article>
        }
        <app-tenant-infos
          class="mt-2"
          [infos]="sidebarInfos || job"
          [hideIbans]="showIbanCheck && !isLocal"
        ></app-tenant-infos>
      </div>
    }
  </div>
}
