"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.objectValues = exports.objectEntries = void 0;
exports.objectEntries = typeof Object.entries === "function" ? Object.entries : function (obj) {
  var entries = [];
  for (var key in obj) {
    if (obj.hasOwnProperty(key) && obj.propertyIsEnumerable(key)) {
      entries.push([key, obj[key]]);
    }
  }
  return entries;
};
exports.objectValues = typeof Object.values === "function" ? Object.values : function (obj) {
  var values = [];
  for (var key in obj) {
    if (obj.hasOwnProperty(key) && obj.propertyIsEnumerable(key)) {
      values.push(obj[key]);
    }
  }
  return values;
};
