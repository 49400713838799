import {
  booleanAttribute,
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import {
  faLayerGroup,
  faRectangleXmark,
} from '@fortawesome/free-solid-svg-icons';
import { clone, find, get, isArray, map, remove } from 'lodash';
import { PROPERTIES } from './tenant-infos.constants';
import { FinoCommonModule } from '@fino-ui/common';
import { NgFor, NgIf } from '@angular/common';
import { FinoTranslationModule } from '@fino-ui/translation';

@Component({
  selector: 'app-tenant-infos',
  templateUrl: './tenant-infos.component.html',
  styleUrls: ['./tenant-infos.component.sass'],
  imports: [FinoTranslationModule, FinoCommonModule, NgFor, NgIf],
})
export class TenantInfosComponent implements OnChanges {
  constructor() {}

  faLayerGroup = faLayerGroup;
  faRectangleXmark = faRectangleXmark;

  @Input()
  isExpanded: boolean = false;

  @Input()
  infos: any;

  @Input({ transform: booleanAttribute })
  hideIbans: boolean;

  propertiesToShow: any[];

  ngOnChanges(changes: SimpleChanges): void {
    this.propertiesToShow = remove(clone(PROPERTIES), (el) => {
      // they want to see oldIban always
      return !(el.path.includes('newIban') && this.hideIbans);
    });
  }

  getProperty(path: string | string[]): string {
    if (isArray(path)) {
      return find(
        map(path, (p) => this.getProperty(p)),
        (e) => e,
      ) as string;
    }
    return get(this.infos, path);
  }

  toggleExpanded() {
    this.isExpanded = !this.isExpanded;
  }
}
