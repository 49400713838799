import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  TemplateRef,
} from '@angular/core';
import { faSpinner, faClose, faCopy } from '@fortawesome/free-solid-svg-icons';
import { first } from 'lodash';
import { ClipboardService } from 'ngx-clipboard';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxMaskDirective } from 'ngx-mask';
import { NgIf, NgFor, NgTemplateOutlet } from '@angular/common';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { FinoCommonModule } from '@fino-ui/common';
import { FinoTranslationModule } from '@fino-ui/translation';

@Component({
  selector: 'app-typeahead',
  templateUrl: './typeahead.component.html',
  imports: [
    FormsModule,
    ReactiveFormsModule,
    NgxMaskDirective,
    NgIf,
    FaIconComponent,
    FinoCommonModule,
    FinoTranslationModule,
    NgFor,
    NgTemplateOutlet,
  ],
})
export class TypeaheadComponent implements OnInit {
  @Input() form: any;
  @Input() formControlName: string = '';
  @Input() results: any;
  @Input() resultTemplate!: TemplateRef<any>;
  @Input() resultSelected: EventEmitter<any> = new EventEmitter();
  @Input() loading = false;
  @Input() mask: string = '';
  @Input() canNavigateByKeys = false;
  @Input() copyWithoutWhitespace = false;

  @Output() keyboardSelected = new EventEmitter();
  @Output() searchChanged = new EventEmitter();

  public showResults = false;
  public faSpinner = faSpinner;
  public faClose = faClose;
  public faCopy = faCopy;

  public selectedIndex = 0;

  public hasFocus = false;

  constructor(private clipboardService: ClipboardService) {}

  get value() {
    return this.form?.controls[this.formControlName]?.value;
  }

  get hasValue() {
    return this.value;
  }

  get hasDanger() {
    const control = this.form.controls[this.formControlName];
    return control.touched && control.errors;
  }

  get error(): string {
    const control = this.form.controls[this.formControlName];
    return `error.${first(Object.keys(control.errors))}`;
  }

  typing() {
    this.showResults = true;
    this.searchChanged.next(this.value);
  }

  onFocus() {
    this.hasFocus = true;
  }

  onBlur() {
    this.hasFocus = false;
  }

  copyToClipboard() {
    const toCopy = this.copyWithoutWhitespace
      ? this.value.replace(' ', '')
      : this.value;
    this.clipboardService.copy(toCopy);
  }

  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(
    event: KeyboardEvent,
  ) {
    this.showResults = false;
  }

  onKeyDown($event: any) {
    switch ($event.key) {
      case 'ArrowDown':
        this.selectedIndex++;
        break;
      case 'ArrowUp':
        this.selectedIndex--;
        break;
      case 'Enter':
        this.keyboardSelected.next(this.selectedIndex);
        break;
    }

    if (this.selectedIndex < 0) {
      this.selectedIndex = this.results.length - 1;
    }
    if (this.selectedIndex >= this.results.length) {
      this.selectedIndex = 0;
    }
  }

  hideResults() {
    this.selectedIndex = 0;
    this.loading = false;
    this.showResults = false;
  }

  ngOnInit(): void {
    this.resultSelected.subscribe((r) => {
      this.showResults = false;
      this.loading = false;
      this.selectedIndex = 0;
    });
  }
}
