import iso7064 from 'iso-7064';

export const DIRECT_DEBITS_FIELDS = [
  'id',
  'creditorId',
  'name',
  'purpose',
  'mref',
  'preferredContact',
  'ignoreCreditor',
];

export const CREDITOR_ID_REGEX = /^[A-Z]{2}\d{2}[A-Z0-9]{3,35}$/;

export const isValidCreditorId = (creditorId: string) => {
  if (!creditorId) return false;
  if (!CREDITOR_ID_REGEX.test(creditorId)) return false;

  function lettersToNumbers(input) {
    return input.replace(/[A-Z]/g, (char) => char.charCodeAt(0) - 55);
  }

  const checksum = parseInt(creditorId.slice(2, 4));
  const countryCode = creditorId.slice(0, 2);

  const digitsToBeConsidered = creditorId.slice(7);
  const toBeComputed = lettersToNumbers(
    digitsToBeConsidered + countryCode + '00',
  );

  const calculatedChecksum = 98 - iso7064.compute(toBeComputed);
  return calculatedChecksum === checksum;
};
