<div class="capture-warrant">
  <div class="columns" *ngIf="!noJobLeft">
    <div class="column pdf-container darkmode-ignore is-6">
      <ng2-pdfjs-viewer
        *ngIf="pdfUrl"
        pagemode="thumbs"
        viewerId="JobPdfViewer"
        [pdfSrc]="pdfUrl"
      ></ng2-pdfjs-viewer>
    </div>

    <form [formGroup]="jobForm" class="column">
      <article *ngIf="job?.hidden" class="message is-warning">
        <div class="message-body" translate="captureWarrant.hidden"></div>
      </article>

      @if (job && showIbanCheck && !isLocal) {
        <app-iban-check
          [newIban]="job.newIban?.value"
          [oldIban]="job.oldIban?.value"
          [id]="job.id"
          [previousJobId]="job.previousJobId"
          (checkSuccess)="ibanCheckSuccess($event)"
        ></app-iban-check>

        <article class="mt-5 panel" [formGroup]="reportForm">
          <p
            class="has-text-white panel-heading"
            translate="captureTransaction.detailCheck"
          ></p>
          <div class="panel-block is-flex-direction-column">
            <div class="is-fullwidth mb-2">
              <fino-select
                [items]="reportPresetItems"
                formControlName="preset"
                label="preset"
                bindLabel="label"
                bindValue="value"
              >
                <div *select-item="let item">
                  <div>{{ item.value | translate }}</div>
                </div>
              </fino-select>
            </div>

            <p translate="captureTransaction.reportDescription"></p>
            <div class="mt-2 is-fullwidth">
              <fino-textarea formControlName="comment" label="comment">
              </fino-textarea>
            </div>
            <div class="mt-2 is-justify-content-flex-start is-fullwidth">
              <button
                [loading]="reportDocumentLoading"
                fino-button
                (click)="reportDocument()"
              >
                <label
                  class="mr-1"
                  translate="captureTransaction.report"
                ></label>
              </button>
            </div>
          </div>
        </article>
      }

      @if (!showIbanCheck || isLocal) {
        <div class="box">
          <h5
            *ngIf="job?.oldIban?.value"
            class="title is-size-5 mb-1"
            translate="captureWarrant.oldIban"
          ></h5>
          <p *ngIf="job?.oldIban?.value">{{ job.oldIban.value | iban }}</p>
          <h5
            *ngIf="job?.newIban?.value"
            class="title is-size-5 mb-1 mt-1"
            translate="captureWarrant.newIban"
          ></h5>
          <p *ngIf="job?.newIban?.value">{{ job.newIban.value | iban }}</p>

          <div
            *ngIf="job?.oldIban?.value || job?.newIban?.value"
            class="divider"
            translate="captureWarrant.IDs"
          ></div>

          <h5
            class="title is-size-5 mb-1"
            translate="captureWarrant.previousJobID"
          ></h5>
          <p *ngIf="job">{{ job.previousJobId }}</p>
          <h5
            class="title is-size-5 mb-1 mt-1"
            translate="captureWarrant.databaseID"
          ></h5>
          <p *ngIf="job">{{ job.id }}</p>
        </div>

        <div class="box">
          <h5
            class="title is-size-5"
            translate="captureWarrant.accountOwner"
          ></h5>

          <fino-input
            formControlName="accountOwnerOneFirstName"
            label="firstName"
          >
            <fino-validation-indicator></fino-validation-indicator>
          </fino-input>

          <fino-input
            formControlName="accountOwnerOneLastName"
            label="lastName"
          >
            <fino-validation-indicator></fino-validation-indicator>
          </fino-input>

          <div *ngIf="hasSecondAccountOwner">
            <h5
              class="title is-size-5"
              translate="captureWarrant.secondAccountOwner"
            ></h5>

            <fino-input
              formControlName="accountOwnerTwoFirstName"
              label="firstName"
            >
              <fino-validation-indicator></fino-validation-indicator>
            </fino-input>

            <fino-input
              formControlName="accountOwnerTwoLastName"
              label="lastName"
            >
              <fino-validation-indicator></fino-validation-indicator>
            </fino-input>
          </div>

          <button
            class="is-fullwidth"
            (click)="toggleSecondAccountOwner()"
            [primary]="true"
            type="button"
            fino-button
          >
            <p translate="captureWarrant.additionalAccountOwner"></p>
            <span
              class="ml-1"
              *ngIf="hasSecondAccountOwner"
              translate="captureWarrant.removeAdditionalAccountOwner"
            ></span>
            <span
              class="ml-1"
              *ngIf="!hasSecondAccountOwner"
              translate="captureWarrant.addAdditionalAccountOwner"
            ></span>
          </button>
        </div>

        <div class="mb-5">
          <div class="box" *ngFor="let field of warrantFields">
            <h5 class="title is-5">
              {{ "captureWarrant." + field.name | translate }}
            </h5>
            <fino-radio-group
              class="rejectionReason"
              [values]="field.values"
              [formControlName]="field.name"
            >
            </fino-radio-group>

            <div
              *ngIf="field.name === 'closeAccount' && showAccountClosingDate"
            >
              <fino-input
                formControlName="accountClosingDate"
                label="accountClosingDate"
              >
                <fino-validation-indicator></fino-validation-indicator>
              </fino-input>
            </div>
          </div>
        </div>

        <article *ngIf="errorMessage" class="message is-danger">
          <div class="message-header">
            <p translate="captureWarrant.error"></p>
            <button class="delete" aria-label="delete"></button>
          </div>
          <div class="message-body">
            {{ errorMessage }}
          </div>
        </article>

        <form [formGroup]="loadNextDocumentForm" class="mb-2">
          <fino-checkbox
            formControlName="loadNext"
            label="captureWarrant.loadNext"
          >
          </fino-checkbox>
        </form>

        <div class="columns mb-2">
          <div class="column">
            <button
              class="is-fullwidth"
              [primary]="true"
              type="submit"
              (click)="submitJob()"
              [disabled]="!jobFormValid"
              [loading]="!submitSubscription.closed"
              fino-button
              translate="captureWarrant.submit"
            ></button>
          </div>
        </div>
      }
    </form>
  </div>

  <div
    class="is-flex is-justify-content-center is-align-items-center is-fullheight"
    *ngIf="noJobLeft"
  >
    <h5 class="title is-size-5" translate="captureWarrant.empty"></h5>
  </div>
</div>
