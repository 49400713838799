<div class="modal-content">
  <div class="modal-header" #handle>
    <h1 class="title" [translate]="title"></h1>
  </div>
  <div class="modal-body">
    <p [translate]="description"></p>
  </div>
  <div class="modal-footer">
    <fino-button-row>
      <button type="button" (click)="close()" fino-button translate>
        approvalModal.abort
      </button>
      <button
        [primary]="true"
        type="button"
        (click)="submit()"
        fino-button
        label="approvalModal.confirm"
      ></button>
    </fino-button-row>
  </div>
</div>
