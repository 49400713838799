import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FinoCommonModule } from '@fino-ui/common';
@Component({
    selector: 'app-sort-icon',
    templateUrl: './sort-icon.component.html',
    styleUrls: ['./sort-icon.component.sass'],
    imports: [FinoCommonModule]
})
export class SortIconComponent implements OnInit, OnChanges {
  constructor() {}

  ngOnChanges(changes: SimpleChanges): void {
    const { currentFilter } = changes;
    if (!currentFilter) return;

    if (currentFilter.currentValue != this.property) {
      this.isSortAsc = false;
      this.isSortDesc = false;
    } else {
      this.isSortAsc = this.sortOrder === 'ASC';
      this.isSortDesc = this.sortOrder === 'DESC';
    }
  }

  isSortDesc: boolean = false;
  isSortAsc: boolean = false;

  @Output() onClick = new EventEmitter();
  @Input() currentFilter: string | undefined = '';
  @Input() sortOrder: string | undefined = '';
  @Input() property: string | undefined = '';

  ngOnInit(): void {}

  sortAsc() {
    this.isSortAsc = !this.isSortAsc;
    if (this.isSortAsc) {
      this.isSortDesc = false;
    }
    this.notify();
  }

  sortDesc() {
    this.isSortDesc = !this.isSortDesc;
    if (this.isSortDesc) {
      this.isSortAsc = false;
    }

    this.notify();
  }

  notify() {
    let sortBy = null;
    if (this.isSortAsc) sortBy = 'ASC';
    if (this.isSortDesc) sortBy = 'DESC';

    this.onClick.next({
      sortBy: sortBy ? sortBy : 'DESC',
      sortField: sortBy ? this.property : null,
    });
  }
}
