import { DatePipe, NgIf, NgFor } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {
  FormControl,
  Validators,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { SubmittableForm } from '@fino-ui/forms';
import { faEdit, faTrash, faCopy } from '@fortawesome/free-solid-svg-icons';
import {
  clone,
  cloneDeep,
  filter,
  isEmpty,
  map,
  merge,
  pick,
  startsWith,
} from 'lodash';
import { updateOrAdd } from 'src/app/core/helpers/helpers';
import {
  EXECUTION_DAY,
  FINANCE_CURRENCY_CODES,
  FINANCE_INTERVAL_TYPES,
  STANDING_ORDER_FIELDS,
} from './standing-orders.constants';

import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';
import { FinoCommonModule } from '@fino-ui/common';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { InputModule } from '@fino-ui/input';
import { SelectModule } from '@fino-ui/select';
import { ButtonModule } from '@fino-ui/button';
import { FinancesCommonModule } from '@fino-ui/finances';
import { TranslateService, FinoTranslationModule } from '@fino-ui/translation';

@Component({
  selector: 'app-standing-orders',
  templateUrl: './standing-orders.component.html',
  styleUrls: ['./standing-orders.component.sass'],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    InputModule,
    SelectModule,
    ButtonModule,
    FinoCommonModule,
    NgIf,
    FinoTranslationModule,
    FaIconComponent,
    NgFor,
    DatePipe,
    FinancesCommonModule,
  ],
})
export class StandingOrdersComponent implements OnInit, OnChanges {
  faEdit = faEdit;
  faTrash = faTrash;
  faCopy = faCopy;

  executionDayItems = EXECUTION_DAY;
  intervalTypeItems = this.mapSelectTypes(FINANCE_INTERVAL_TYPES);
  currencyCodesItems = FINANCE_CURRENCY_CODES;
  standingOrderFields = STANDING_ORDER_FIELDS;

  @ViewChild('intervalSelect', { static: false }) intervalSelect: any;
  @ViewChild('executionDaySelect', { static: false }) executionDaySelect: any;

  toggleAllDelete: boolean = true;
  datePipe = new DatePipe('de-DE');

  public searchForm: SubmittableForm = new SubmittableForm({
    search: new FormControl('', []),
  });

  datePickerConfig = {
    adaptivePosition: true,
    containerClass: 'theme-default',
    dateInputFormat: 'DD.MM.YYYY',
  };

  @Input()
  public form: SubmittableForm = new SubmittableForm({});

  @Input()
  public standingOrders: any[] = [];

  public filteredStandingOrders: any[] = [];

  @Output() standingOrdersSubmitted = new EventEmitter<any>();

  isUpdateMode: boolean = false;

  constructor(public translateService: TranslateService) {}

  get hasIban() {
    return !isEmpty(this.form.controls['iban']?.value);
  }

  get hasComment() {
    return this.form.controls['comment'].value;
  }

  ngOnChanges(changes: SimpleChanges): void {
    const { standingOrders } = changes;
    if (standingOrders) {
      this.filteredStandingOrders = clone(this.standingOrders);
    }
  }

  transformToDate(date: string) {
    if (moment(date, 'DD.MM.YYYY').isValid())
      return moment(date, 'DD.MM.YYYY').toDate();
    return date;
  }

  submit() {
    this.form.markAllAsTouched();
    if (this.form.valid || this.hasComment) {
      const toBeAdded = this.form.value;
      if (!toBeAdded.id) {
        toBeAdded.id = uuidv4();
      }
      this.isUpdateMode = false;

      updateOrAdd(
        this.standingOrders,
        merge(toBeAdded, { selected: true }),
        'id',
      );
      this.filteredStandingOrders = clone(this.standingOrders);
      this.searchForm.reset();

      this.standingOrdersSubmitted.next(this.form.value);
      this.form.reset();
      this.initForm();

      this.intervalSelect.select.handleClearClick();
      this.executionDaySelect.select.handleClearClick();

      this.form.updateValueAndValidity();
    }
  }

  get hasStandingOrders() {
    return this.standingOrders && this.standingOrders.length > 0;
  }

  deleteItem(item: any, selected: boolean = true) {
    item.selected = selected;
    this.standingOrdersSubmitted.next(this.form.value);
  }

  setupFilterForm() {
    this.searchForm.controls['search'].valueChanges.subscribe(
      (keyword: string) => {
        if (!keyword) {
          this.filteredStandingOrders = clone(this.standingOrders);
          return;
        }
        this.filteredStandingOrders = filter(this.standingOrders, (item) =>
          item.name.toLowerCase().includes(keyword.toLowerCase()),
        );
      },
    );
  }

  duplicateItem(item: any) {
    const cloned = cloneDeep(item);
    cloned.id = uuidv4();
    this.applyModelToForm(cloned);
  }

  deleteAllItems() {
    this.toggleAllDelete = !this.toggleAllDelete;
    map(this.standingOrders, (d) => this.deleteItem(d, this.toggleAllDelete));
  }

  get isFormValid() {
    return this.form.valid;
  }

  editItem(item: any) {
    this.isUpdateMode = true;
    this.applyModelToForm(item);
  }

  applyModelToForm(item: any) {
    const pickedItems = pick(item, this.standingOrderFields);

    // TODO: can be removed just for legacy support
    if (
      pickedItems['firstExecution'] &&
      !moment(pickedItems['firstExecution'], 'DD.MM.YYYY').isValid()
    ) {
      pickedItems['firstExecution'] = this.datePipe.transform(
        pickedItems['firstExecution'],
      );
    }
    if (
      pickedItems['lastExecution'] &&
      !moment(pickedItems['lastExecution'], 'DD.MM.YYYY').isValid()
    ) {
      pickedItems['lastExecution'] = this.datePipe.transform(
        pickedItems['lastExecution'],
      );
    }

    if (
      pickedItems['nextExecution'] &&
      !moment(pickedItems['nextExecution'], 'DD.MM.YYYY').isValid()
    ) {
      pickedItems['nextExecution'] = this.datePipe.transform(
        pickedItems['nextExecution'],
      );
    }
    this.form.patchValue(pickedItems);

    this.intervalSelect.setValue(pickedItems['interval']);
    this.executionDaySelect.setValue(pickedItems['executionDay']);
  }

  mapSelectTypes(types: any) {
    return map(types, (type) => {
      return {
        label: this.translateService.instant(type),
        value: type,
      };
    });
  }

  initForm() {
    this.form.controls['currency']?.setValue('EUR');
  }

  ngOnInit(): void {
    this.initForm();
    this.setupFilterForm();
    this.form.controls['iban']?.valueChanges.subscribe((r) => {
      this.form
        .get('bic')
        ?.setValidators(isEmpty(r) ? [Validators.required] : []);

      this.form.get('bic')?.updateValueAndValidity();
    });
  }
}
