import { FormControl } from '@angular/forms';
import { SubmittableForm } from '@fino-ui/forms';

export const WARRANT_FIELDS = [
  {
    name: 'transferCredits',
    values: [true, false],
  },
  {
    name: 'transferDebits',
    values: [true, false],
  },
  {
    name: 'transferStandingOrders',
    values: [true, false],
  },
  {
    name: 'transferBalance',
    values: [true, false],
  },
  {
    name: 'closeAccount',
    values: [true, false],
  },
];

export const reportForm = new SubmittableForm({
  comment: new FormControl('', []),
  preset: new FormControl(null, []),
});

export const COMMENT_PRESETS = [
  {
    label: 'UNREADABLE',
    value: 'UNREADABLE',
  },
  {
    label: 'INCOMPLETE',
    value: 'INCOMPLETE',
  },
  {
    label: 'NO_WARRANT',
    value: 'NO_WARRANT',
  },
];
