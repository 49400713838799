import { Component } from '@angular/core';
import { NgxModalComponent } from 'ngx-modalview';
import { CaptureTransactionService } from '../capture-transaction.service';
import { ButtonModule } from '@fino-ui/button';
import { FinoCommonModule } from '@fino-ui/common';
import { FinoTranslationModule } from '@fino-ui/translation';

@Component({
  selector: 'app-submit-modal',
  templateUrl: './submit-modal.component.html',
  imports: [FinoTranslationModule, ButtonModule, FinoCommonModule],
})
export class SubmitModalComponent extends NgxModalComponent<{}, {}> {
  constructor(private captureTransactionService: CaptureTransactionService) {
    super();
  }

  submit() {
    this.result = true;
    this.close();
  }
}
