import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslationModule } from '@fino-ui/translation';
import { KeycloakService } from 'keycloak-angular';
import { Organizations } from 'src/app/core/auth/organizations';
import { Routes } from 'src/app/core/routes/routes';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  imports: [TranslationModule],
  styleUrls: ['./home.component.sass'],
})
export class HomeComponent implements OnInit {
  constructor(
    private authService: KeycloakService,
    private router: Router,
  ) {}

  ngOnInit(): void {
    const userProfile = this.authService.getKeycloakInstance().tokenParsed;
    const organizations = Object.values(Organizations);

    const navigateToOverview = organizations.some((org) =>
      userProfile?.organization?.includes(org),
    );
    if (navigateToOverview) {
      this.router.navigate([Routes.TicketOverview]);
    }
  }
}
