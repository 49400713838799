// Specifies the diagrams to show and the color in the ticket overview
export const TICKET_TYPE_COLOR = {
  'digital.fino.cyclope.api.events.receiving.AllDocumentsSent': '#7aa2f7',
  'digital.fino.cyclope.api.events.receiving.TransactionDataReceived':
    '#9ece6a',
  'digital.fino.cyclope.api.events.FaxDocumentReceived': '#f7768e',
  'digital.fino.cyclope.api.events.receiving.BalanceListDocumentReceived':
    '#bb9af7',
  'digital.fino.cyclope.api.events.receiving.WarrantSigned': '#e0af68',
  'digital.fino.cyclope.api.events.transferring.TransactionDataComplete':
    '#7dcfff',
  'digital.fino.cyclope.api.events.transferring.RequestDataCompleted':
    '#9d7cd8',
  'digital.fino.cyclope.api.events.receiving.RequestFormFilled': '#ff9e64',
  'digital.fino.cyclope.api.events.transferring.AcceptedOutgoingRequest':
    '#3d59a1',
  DEPOT_TRANSFER_STARTED: '#89ddff',
  'digital.fino.cyclope.api.events.transferring.ReceivedOutgoingRequest':
    '#f7768e',
  'digital.fino.cyclope.api.events.receiving.BalanceListDataReceived':
    '#7aa2f7',
  'digital.fino.cyclope.api.events.receiving.InitializationConfirmedByUser':
    '#9ece6a',
  'digital.fino.cyclope.api.events.receiving.WarrantShipmentSkipped': '#e0af68',
  'digital.fino.cyclope.api.events.receiving.DocumentSendingStarted': '#bb9af7',
  'digital.fino.cyclope.api.events.receiving.StandingOrdersProcessed':
    '#7dcfff',
  'digital.fino.cyclope.api.events.BalanceListWithdrawn': '#ff9e64',
  'digital.fino.cyclope.api.domain.DwsOrderCompleted': '#3d59a1',
  'digital.fino.cyclope.api.events.RequestCompleted': '#9d7cd8',
  'digital.fino.cyclope.api.events.transferring.FinancesVerified': '#e0af68',
  'digital.fino.cyclope.api.events.receiving.InitializationConfirmed':
    '#7aa2f7',
  'digital.fino.cyclope.api.events.transferring.TransactionDataAdded':
    '#9ece6a',
  'digital.fino.cyclope.api.events.receiving.VerifiedFinances': '#bb9af7',
  'digital.fino.cyclope.api.events.receiving.SentRequestToTransferring':
    '#ff9e64',
  'digital.fino.cyclope.api.events.receiving.ReceivedRejectionDocument':
    '#f7768e',
};
