import { Directive, OnInit } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';
import { Organizations } from 'src/app/core/auth/organizations';
import { FRESHDESK_FAQS } from './freshdesk.constants';

@Directive({
    selector: 'app-freshdesk',
    standalone: true,
})
export class FreshdeskComponent implements OnInit {
  constructor(private authService: KeycloakService) {}

  ngOnInit(): void {
    const userProfile = this.authService.getKeycloakInstance().tokenParsed;
    const isSMM = userProfile?.organization?.includes(Organizations.SMM);

    this.loadFreshDesk(isSMM ? FRESHDESK_FAQS.SMM : FRESHDESK_FAQS.DEFAULT);
  }

  loadFreshDesk(widgetID: string): void {
    //@ts-ignore
    window.fwSettings = {
      widget_id: widgetID,
    };

    () => {
      if ('function' !== typeof (window as any).FreshworksWidget) {
        const n = function () {
          n.q.push(arguments);
        } as any;
        (n.q = []), ((window as any).FreshworksWidget = n);
      }
    };
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = `https://euc-widget.freshworks.com/widgets/${widgetID}.js`;
    document.getElementsByTagName('head')[0].appendChild(script);
  }
}
