import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SubmittableForm } from '@fino-ui/forms';
import { TicketOverviewService } from './ticket-overview.service';
import {
  faCircleXmark,
  faChartSimple,
  faSpinner,
  faBuildingColumns,
  faRefresh,
} from '@fortawesome/free-solid-svg-icons';
import { interval, Observable, Subject, Subscription, takeUntil } from 'rxjs';
import { map } from 'lodash';
import { SelectComponent, SelectModule } from '@fino-ui/select';
import { LocalStorage } from 'ngx-webstorage';
import { KeycloakService } from 'keycloak-angular';
import { FinoCommonModule } from '@fino-ui/common';
import { NgIf } from '@angular/common';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { TicketOverviewChartComponent } from './ticket-overview-chart/ticket-overview-chart.component';
import { TicketTableComponent } from './ticket-table/ticket-table.component';
import { InputModule } from '@fino-ui/input';
import { ButtonModule } from '@fino-ui/button';
import { FinoTranslationModule } from '@fino-ui/translation';

const INTERVAL_REFRESH = 1000 * 60 * 2;

@Component({
  selector: 'app-ticket-overview',
  templateUrl: './ticket-overview.component.html',
  styleUrls: ['./ticket-overview.component.sass'],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    ButtonModule,
    FinoCommonModule,
    NgIf,
    FaIconComponent,
    SelectModule,
    TicketOverviewChartComponent,
    InputModule,
    TicketTableComponent,
    FinoTranslationModule,
  ],
})
export class TicketOverviewComponent implements OnInit, OnDestroy {
  constructor(
    private ticketOverviewService: TicketOverviewService,
    private authService: KeycloakService,
  ) {}

  ngOnDestroy(): void {
    this.onDestroy$.next();
  }

  loadingSub: Subscription = Subscription.EMPTY;
  faSpinner = faSpinner;
  faBuildingColumns = faBuildingColumns;
  faRefresh = faRefresh;
  showBankList = false;

  private onDestroy$ = new Subject<void>();

  public searchForm: SubmittableForm = new SubmittableForm({
    keyword: new FormControl(''),
    showOnlyRemindersPaused: new FormControl(),
    showOnlyOpenStandingOrders: new FormControl(),
  });

  public bankForm: SubmittableForm = new SubmittableForm({
    bank: new FormControl(''),
  });

  get hasTenants() {
    return this.tenants?.length > 0;
  }

  @ViewChild('tenantSelect')
  tenantSelect: SelectComponent | undefined;

  public blacklistStatus: string[] = [];
  public tenants: any = [];
  public tickets: any[] = [];
  public pagination: any = {};
  public statistic = {};
  public sortMode: boolean = false;
  @LocalStorage()
  public sortOrder: string | undefined;
  @LocalStorage()
  public filterProperty: string | undefined;
  @LocalStorage()
  public hideChart: boolean | undefined;

  public searchTerm: string = '';
  public showOnlyRemindersPaused: boolean = false;
  public showOnlyOpenStandingOrders: boolean = false;

  public currentPage: number = 1;

  public faCircleXmark = faCircleXmark;
  public faChartSimple = faChartSimple;
  public interval: Observable<number> = new Observable();
  public organization: string;

  ngOnInit(): void {
    this.interval = interval(INTERVAL_REFRESH).pipe(takeUntil(this.onDestroy$));
    this.interval.subscribe(() => this.fetchTickets());

    const userProfile = this.authService.getKeycloakInstance().tokenParsed;
    this.organization = userProfile?.organization;

    this.fetchTickets(
      this.organization,
      this.searchTerm,
      this.filterProperty,
      this.sortOrder,
    );

    this.ticketOverviewService
      .fetchTenants(this.organization)
      .subscribe((tenants) => {
        this.tenants = map(tenants, (t) => this.adjustTenant(t));
      });

    this.searchForm.valueChanges.subscribe((value) => {
      this.searchTerm = value.keyword;
      this.showOnlyRemindersPaused = value.showOnlyRemindersPaused;
      this.showOnlyOpenStandingOrders = value.showOnlyOpenStandingOrders;

      this.currentPage = 1;
      this.fetchTickets(this.organization, value.keyword);
    });
  }

  get lastPage() {
    return (
      this.pagination && Math.ceil(this.pagination.total / this.pagination.size)
    );
  }

  get isLoading() {
    return !this.loadingSub.closed;
  }

  get hasTickets() {
    return this.tickets.length > 0;
  }

  nextPage() {
    this.goToPage(this.currentPage + 1);
  }

  adjustTenant(tenant: any) {
    tenant.urlWithoutProtocol = tenant.url.replace('http://', '');
    return tenant;
  }

  toggleBankList() {
    this.showBankList = !this.showBankList;
    setTimeout(() => {
      this.tenantSelect?.focus();
    });
  }

  selectBank(tenant: any) {
    this.ticketOverviewService
      .getTenantLink(tenant.name, this.organization)
      .subscribe((res: any) => this.openKWH(res));
  }

  openKWH(response: any, id = '') {
    this.ticketOverviewService.openKWH(response, id);
  }

  previousPage() {
    this.goToPage(this.currentPage - 1);
  }

  goToPage(page: number) {
    this.currentPage = page;
    this.fetchTickets(
      this.organization,
      this.searchTerm,
      this.filterProperty,
      this.sortOrder,
      this.currentPage,
    );
  }

  fetchTickets(
    organization: string = this.organization,
    term: string = '',
    sortField: string = '',
    sortOrder: string = 'DESC',
    page: number = 1,
  ) {
    this.filterProperty = sortField;
    this.sortOrder = sortOrder;
    this.loadingSub = this.ticketOverviewService
      .fetchTickets(
        organization,
        term,
        sortField,
        sortOrder,
        page,
        this.blacklistStatus,
        this.showOnlyRemindersPaused,
        this.showOnlyOpenStandingOrders,
      )
      .subscribe((tickets: any) => {
        this.tickets = tickets.data;
        this.pagination = tickets.page;
        this.statistic = tickets.totalCounts;
      });
  }

  onLegendClick($event: string[]) {
    this.blacklistStatus = $event;
    this.fetchTickets(this.organization);
  }

  toggleChart() {
    this.hideChart = !this.hideChart;
  }

  sortByField(event: any) {
    this.currentPage = 1;
    this.fetchTickets(
      this.organization,
      this.searchTerm,
      event.sortField,
      event.sortBy,
    );
  }
}
