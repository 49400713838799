import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Api } from 'src/app/core/api/Api';

@Injectable({
  providedIn: 'root',
})
export class SiblingsService {
  constructor(private http: HttpClient) {}

  createPreview(documentsToDismiss: any[], documentsToMerge: any[]) {
    return this.http.post(Api.Siblings.mergePreview, {
      documentsToDismiss,
      documentsToMerge,
    });
  }

  merge(documentsToDismiss: any[], documentsToMerge: any[]) {
    return this.http.post(Api.Siblings.merge, {
      documentsToDismiss,
      documentsToMerge,
    });
  }
}
