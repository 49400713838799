import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  FormControl,
  Validators,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { SubmittableForm } from '@fino-ui/forms';
import { NgIf } from '@angular/common';
import { InputModule } from '@fino-ui/input';
import { FinoTranslationModule } from '@fino-ui/translation';

@Component({
  selector: 'app-iban-check',
  templateUrl: './iban-check.component.html',
  styleUrls: ['./iban-check.component.sass'],
  imports: [
    FinoTranslationModule,
    NgIf,
    FormsModule,
    ReactiveFormsModule,
    InputModule,
  ],
})
export class IbanCheckComponent implements OnInit {
  constructor() {}

  public ibanForm: SubmittableForm = new SubmittableForm({
    newIbanStart: new FormControl('', Validators.required),
    newIbanEnd: new FormControl('', [Validators.required]),
    oldIbanEnd: new FormControl('', [Validators.required]),
  });

  newIbanStartValid = false;
  newIbanEndValid = false;
  oldIbanEndValid = false;

  @Input('id')
  public id: any;

  @Input('previousJobId')
  public previousJobId: any;

  @Input('newIban')
  public newIban: string = '';

  @Input('oldIban')
  public oldIban: string = '';

  @Input('isQualityAssurance')
  public isQualityAssurance: boolean = false;

  @Output('checkSuccess')
  public checkSuccess = new EventEmitter();

  notifyAboutCheck() {
    let success = this.newIbanStartValid && this.newIbanEndValid;

    if (!this.isQualityAssurance) {
      success = success && this.oldIbanEndValid;
    }

    if (success) {
      this.ibanForm.reset();
    }
    this.checkSuccess.emit(success);
  }

  ngOnInit(): void {
    this.ibanForm.controls['newIbanStart']?.valueChanges.subscribe((v) => {
      const { newIban } = this;
      if (!newIban) return;

      const newIbanStart = newIban.slice(2, 4);
      this.newIbanStartValid = newIbanStart === v;

      if (!this.newIbanStartValid) {
        this.ibanForm.controls['newIbanStart'].setErrors({
          newIbanStart: true,
        });
      }
      this.notifyAboutCheck();
    });

    this.ibanForm.controls['newIbanEnd']?.valueChanges.subscribe((v) => {
      const { newIban } = this;
      if (!newIban) return;

      this.newIbanEndValid = newIban.slice(-6) === v;
      if (!this.newIbanEndValid) {
        this.ibanForm.controls['newIbanEnd'].setErrors({
          newIbanEnd: true,
        });
      }
      this.notifyAboutCheck();
    });

    this.ibanForm.controls['oldIbanEnd']?.valueChanges.subscribe((v) => {
      const { oldIban } = this;
      if (!oldIban) return;

      const lastTenDigits = oldIban.slice(-10);
      const regex = new RegExp(`^\\d{0,4}${v}$`, 'gm');
      this.oldIbanEndValid = regex.test(lastTenDigits);
      if (!this.oldIbanEndValid) {
        this.ibanForm.controls['oldIbanEnd'].setErrors({
          oldIbanEnd: true,
        });
      }
      this.notifyAboutCheck();
    });
  }
}
