<div class="box">
  <h5 class="title is-size-5 mb-1" translate="ibanCheck.previousJobId"></h5>
  <p *ngIf="previousJobId">{{ previousJobId }}</p>
  <h5 class="title is-size-5 mb-1 mt-1" translate="ibanCheck.id"></h5>
  <p *ngIf="id">{{ id }}</p>
</div>

<form [formGroup]="ibanForm">
  <h3 class="title is-size-3 mb-1" translate="ibanCheck.title"></h3>
  <p></p>
  <div class="mt-2 mb-2">
    <span translate="ibanCheck.example1"></span
    ><span class="is-size-4 is-underlined has-text-primary has-text-weight-bold"
      >40</span
    >
    5876 0954 0100 7060 54
  </div>

  <fino-input formControlName="newIbanStart" label="newIbanStart.label">
    <fino-validation-indicator></fino-validation-indicator>
  </fino-input>

  <div class="mt-2 mb-2">
    <span translate="ibanCheck.example2"></span>
    <span class="is-size-4 is-underlined has-text-primary has-text-weight-bold"
      >7060 54</span
    >
  </div>

  <fino-input formControlName="newIbanEnd" label="newIbanEnd.label">
    <fino-validation-indicator></fino-validation-indicator>
  </fino-input>

  <div *ngIf="!isQualityAssurance">
    <hr />

    <div class="mt-2 mb-2">
      <span translate="ibanCheck.example3"></span>
      <span
        class="is-size-4 is-underlined has-text-primary has-text-weight-bold"
        >00 7060 54</span
      >
    </div>

    <fino-input formControlName="oldIbanEnd" label="oldIbanEnd.label">
      <fino-validation-indicator></fino-validation-indicator>
    </fino-input>
  </div>
</form>
