import { ThemingService } from '@fino-ui/theming';
import { of } from 'rxjs';

export function initilizeTheme(themingService: ThemingService) {
  return () =>
    themingService.renderTheme({
      name: 'default',
      primaryColor: '#8dc151',
      tenant: 'none',
      tenantDisplayName: 'KWH Courier UI',
    });
}
