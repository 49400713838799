"use strict";

var _a, _b;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.BbanStructurePart = exports.CharacterType = exports.PartType = void 0;
var randInt_1 = require("./randInt");
var PartType;
(function (PartType) {
  PartType[PartType["BANK_CODE"] = 0] = "BANK_CODE";
  PartType[PartType["BRANCH_CODE"] = 1] = "BRANCH_CODE";
  PartType[PartType["ACCOUNT_NUMBER"] = 2] = "ACCOUNT_NUMBER";
  PartType[PartType["NATIONAL_CHECK_DIGIT"] = 3] = "NATIONAL_CHECK_DIGIT";
  PartType[PartType["CURRENCY_TYPE"] = 4] = "CURRENCY_TYPE";
  PartType[PartType["ACCOUNT_TYPE"] = 5] = "ACCOUNT_TYPE";
  PartType[PartType["OWNER_ACCOUNT_NUMBER"] = 6] = "OWNER_ACCOUNT_NUMBER";
  PartType[PartType["IDENTIFICATION_NUMBER"] = 7] = "IDENTIFICATION_NUMBER";
})(PartType = exports.PartType || (exports.PartType = {}));
var CharacterType;
(function (CharacterType) {
  CharacterType[CharacterType["n"] = 0] = "n";
  CharacterType[CharacterType["a"] = 1] = "a";
  CharacterType[CharacterType["c"] = 2] = "c";
  CharacterType[CharacterType["e"] = 3] = "e";
})(CharacterType = exports.CharacterType || (exports.CharacterType = {}));
var charByCharacterType = (_a = {}, _a[CharacterType.n] = "0123456789", _a[CharacterType.a] = "ABCDEFGHIJKLMNOPQRSTUVWXYZ", _a[CharacterType.c] = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ", _a[CharacterType.e] = " ", _a);
var charByCharacterRE = (_b = {}, _b[CharacterType.n] = /^[0-9]+$/, _b[CharacterType.a] = /^[A-Z]+$/, _b[CharacterType.c] = /^[0-9A-Za-z]+$/, _b[CharacterType.e] = /^ +$/, _b);
var BbanStructurePart = function () {
  function BbanStructurePart(entryType, characterType, length, trailingSeparator, generate) {
    this.entryType = entryType;
    this.characterType = characterType;
    this.length = length;
    this.generate = generate || this.defaultGenerator;
    this.hasGenerator = !!generate;
    this.trailingSeparator = trailingSeparator;
  }
  BbanStructurePart.bankCode = function (length, characterType, trailingSeparator) {
    if (trailingSeparator === void 0) {
      trailingSeparator = true;
    }
    return new BbanStructurePart(PartType.BANK_CODE, characterType, length, trailingSeparator);
  };
  BbanStructurePart.branchCode = function (length, characterType, trailingSeparator) {
    if (trailingSeparator === void 0) {
      trailingSeparator = true;
    }
    return new BbanStructurePart(PartType.BRANCH_CODE, characterType, length, trailingSeparator);
  };
  BbanStructurePart.accountNumber = function (length, characterType, trailingSeparator) {
    if (trailingSeparator === void 0) {
      trailingSeparator = true;
    }
    return new BbanStructurePart(PartType.ACCOUNT_NUMBER, characterType, length, trailingSeparator);
  };
  BbanStructurePart.nationalCheckDigit = function (length, characterType, generate, trailingSeparator) {
    if (trailingSeparator === void 0) {
      trailingSeparator = false;
    }
    return new BbanStructurePart(PartType.NATIONAL_CHECK_DIGIT, characterType, length, trailingSeparator, generate);
  };
  BbanStructurePart.accountType = function (length, characterType, trailingSeparator) {
    if (trailingSeparator === void 0) {
      trailingSeparator = false;
    }
    return new BbanStructurePart(PartType.ACCOUNT_TYPE, characterType, length, trailingSeparator);
  };
  BbanStructurePart.currencyType = function (length, characterType, trailingSeparator) {
    if (trailingSeparator === void 0) {
      trailingSeparator = false;
    }
    return new BbanStructurePart(PartType.CURRENCY_TYPE, characterType, length, trailingSeparator);
  };
  BbanStructurePart.ownerAccountNumber = function (length, characterType, trailingSeparator) {
    if (trailingSeparator === void 0) {
      trailingSeparator = true;
    }
    return new BbanStructurePart(PartType.OWNER_ACCOUNT_NUMBER, characterType, length, trailingSeparator);
  };
  BbanStructurePart.identificationNumber = function (length, characterType, trailingSeparator) {
    if (trailingSeparator === void 0) {
      trailingSeparator = true;
    }
    return new BbanStructurePart(PartType.IDENTIFICATION_NUMBER, characterType, length, trailingSeparator);
  };
  BbanStructurePart.prototype.getPartType = function () {
    return this.entryType;
  };
  BbanStructurePart.prototype.getCharacterType = function () {
    return this.characterType;
  };
  BbanStructurePart.prototype.getLength = function () {
    return this.length;
  };
  BbanStructurePart.prototype.validate = function (value) {
    return charByCharacterRE[this.characterType].test(value);
  };
  BbanStructurePart.prototype.defaultGenerator = function (bban, structure) {
    var charChoices = charByCharacterType[this.characterType];
    var s = [];
    for (var i = 0; i < this.getLength(); i += 1) {
      s.push(charChoices[randInt_1.randInt(charChoices.length)]);
    }
    return s.join("");
  };
  return BbanStructurePart;
}();
exports.BbanStructurePart = BbanStructurePart;
