import { Component, OnInit } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { SubmittableForm } from '@fino-ui/forms';
import { faStar } from '@fortawesome/free-solid-svg-icons';
import { KeycloakService } from 'keycloak-angular';

import { filter, merge } from 'lodash';
import { Subscription } from 'rxjs';
import { NavbarService } from 'src/app/components/navbar/navbar.service';
import { EnvironmentService } from 'src/app/core/environment/environment.service';
import { Routes } from 'src/app/core/routes/routes';
import { IdentifyDocumentService } from '../identify-document/identify-document.service';
import {
  COMMENT_PRESETS,
  DOCUMENT_STATUS,
} from './capture-transaction.constants';
import { CaptureTransactionService } from './capture-transaction.service';
import {
  directDebitsForm,
  receivedPaymentsForm,
  reportForm,
  standingOrdersForm,
} from './capture-transaction.form.constants';
import { HttpErrorResponse } from '@angular/common/http';
import { SubmitModalComponent } from './submit-modal/submit-modal.component';
import { NgxModalService } from 'ngx-modalview';
import { Organizations } from 'src/app/core/auth/organizations';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { FinoCommonModule } from '@fino-ui/common';
import { PdfJsViewerModule } from 'ng2-pdfjs-viewer';
import { UnlockTransactionListComponent } from './unlock-transaction-list/unlock-transaction-list.component';
import { IbanCheckComponent } from '../../components/iban-check/iban-check.component';
import { NgFor, KeyValuePipe } from '@angular/common';
import { DirectDebitsComponent } from '../../components/direct-debits/direct-debits.component';
import { ReceivedPaymentsComponent } from '../../components/recieved-payments/recieved-payments.component';
import { StandingOrdersComponent } from '../../components/standing-orders/standing-orders.component';
import { TenantInfosComponent } from '../../components/tenant-infos/tenant-infos.component';
import { InputModule } from '@fino-ui/input';
import { SelectModule } from '@fino-ui/select';
import { ButtonModule } from '@fino-ui/button';
import { FinoTranslationModule } from '@fino-ui/translation';

const MODES = {
  DIRECT_DEBITS: 'directDebits',
  RECIEVED_PAYMENTS: 'receivedPayments',
  STANDING_ORDERS: 'standingOrders',
};

@Component({
  selector: 'app-capture-transaction',
  templateUrl: './capture-transaction.component.html',
  styleUrls: ['./capture-transaction.component.sass'],
  imports: [
    FaIconComponent,
    FinoCommonModule,
    PdfJsViewerModule,
    UnlockTransactionListComponent,
    FinoTranslationModule,
    IbanCheckComponent,
    DirectDebitsComponent,
    ReceivedPaymentsComponent,
    StandingOrdersComponent,
    FormsModule,
    ReactiveFormsModule,
    InputModule,
    ButtonModule,
    SelectModule,
    TenantInfosComponent,
    KeyValuePipe,
  ],
})
export class CaptureTransactionComponent implements OnInit {
  public pdfUrl: string = '';
  public job: any;
  public noJobLeft: boolean = false;

  public modes = MODES;

  faStar = faStar;

  public currentPage: number = 0;

  public selectedMode: string = MODES.DIRECT_DEBITS;
  public tenantInfos: any;
  public sidebarInfos: any;

  public reportPresetItems = COMMENT_PRESETS;

  submitSubscription = Subscription.EMPTY;

  public showIbanCheck: boolean = true;
  public approvedWithCorrections: boolean = false;
  public extractionAccepted: boolean = true;
  public forceQA: boolean = false;

  public dontRenderNextJob = false;
  public useRotatedDocUrl = false;
  public userName: string = '';
  public unlockBalanceListSubscription: Subscription = Subscription.EMPTY;

  get isLocal() {
    return this.envService.isLocal;
  }

  get rotatedDocUrl() {
    return this.job && this.job.rotatedDocUrl;
  }

  get statusIsClosed() {
    return this.job && this.job.status == DOCUMENT_STATUS.CLOSED;
  }

  get documentIsHidden() {
    return this.job && this.job.hidden;
  }

  public directDebitsForm: SubmittableForm = directDebitsForm;

  public receivedPaymentsForm: SubmittableForm = receivedPaymentsForm;

  public reportForm: SubmittableForm = reportForm;

  public reportSubscription: Subscription = Subscription.EMPTY;

  public standingOrdersForm: SubmittableForm = standingOrdersForm;

  public loadNextDocumentForm: SubmittableForm = new SubmittableForm({
    loadNext: new FormControl(true),
  });

  public directDebits: any[] = [];
  public receivedPayments: any[] = [];
  public standingOrders: any[] = [];

  public isSMMUser: boolean = false;

  constructor(
    private captureTransactionService: CaptureTransactionService,
    private identifyDocumentService: IdentifyDocumentService,
    private navbarService: NavbarService,
    private route: ActivatedRoute,
    private router: Router,
    private envService: EnvironmentService,
    private authService: KeycloakService,
    private ngxModalService: NgxModalService,
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe((r) => {
      this.renderJob(r['id']);
    });

    const userProfile = this.authService.getKeycloakInstance().tokenParsed;

    this.userName = userProfile?.email;
    this.isSMMUser = userProfile?.organization?.includes(Organizations.SMM);

    this.reportForm.controls['preset']?.valueChanges.subscribe((change) => {
      this.reportForm.patchValue({
        comment: change,
      });
    });

    this.loadNextDocumentForm.valueChanges.subscribe((r) => {
      const { loadNext } = r;
      this.dontRenderNextJob = !loadNext;
    });
  }

  countItemsBy(mode: string, countAll: boolean = false) {
    let items = [];
    switch (mode) {
      case MODES.DIRECT_DEBITS:
        items = this.directDebits;
        break;
      case MODES.RECIEVED_PAYMENTS:
        items = this.receivedPayments;
        break;
      case MODES.STANDING_ORDERS:
        items = this.standingOrders;
        break;
    }

    return countAll ? items.length : filter(items, { selected: true }).length;
  }

  toggleRotatedDocUrl() {
    this.useRotatedDocUrl = !this.useRotatedDocUrl;
    this.pdfUrl = '';
    setTimeout(() => {
      this.pdfUrl = escape(
        this.useRotatedDocUrl ? this.rotatedDocUrl : this.job.docUrl,
      );
    }, 100);
  }

  get isQualityAssurance() {
    return this.route.snapshot.data['isQualityAssurance'];
  }

  resetForms() {
    this.directDebitsForm.reset();
  }

  directDebitsSubmitted($event: any) {
    this.approvedWithCorrections = true;
  }

  receivedPaymentsSubmitted($event: any) {
    this.approvedWithCorrections = true;
  }

  ibanCheckSuccess($event: boolean) {
    this.showIbanCheck = !$event;
  }

  reportDocument() {
    this.reportSubscription = this.captureTransactionService
      .reportDocument(this.job.id, this.reportForm.controls['comment'].value, {
        directDebits: this.directDebits,
        receivedPayments: this.receivedPayments,
        standingOrders: this.standingOrders,
      })
      .subscribe(() => {
        this.reportForm.reset();
        this.renderNextJob();
      });
  }

  get reportDocumentLoading() {
    return !this.reportSubscription?.closed;
  }

  standingOrdersSubmitted($event: any) {
    this.approvedWithCorrections = true;
  }

  renderJob(id: string = '') {
    this.navbarService.loadCounts.next('balanceList');

    this.resetForms();
    this.captureTransactionService
      .fetchNextJob(id, this.isQualityAssurance)
      .subscribe({
        next: (result: any) => {
          if (!result) {
            this.noJobLeft = true;
            return;
          }
          this.job = result;
          this.showIbanCheck = true;
          this.forceQA = false;

          // used for qa
          this.extractionAccepted = true;
          this.approvedWithCorrections = false;

          const { capturedFinances, extractedFinances } = this.job;
          if (capturedFinances) {
            const { directDebits, receivedPayments, standingOrders } =
              this.captureTransactionService.applyCapturedFinances(
                capturedFinances,
              );

            this.directDebits = directDebits;
            this.receivedPayments = receivedPayments;
            this.standingOrders = standingOrders;
          } else if (extractedFinances) {
            const { directDebits, receivedPayments, standingOrders } =
              this.captureTransactionService.prepareFromFinctract(
                extractedFinances,
              );

            this.directDebits = directDebits;
            this.receivedPayments = receivedPayments;
            this.standingOrders = standingOrders;
          } else {
            this.directDebits = [];
            this.receivedPayments = [];
            this.standingOrders = [];
          }

          if (this.job.tenant) {
            this.identifyDocumentService
              .queryBLZ(this.job.tenant)
              .subscribe((res) => {
                this.tenantInfos = res;
                this.sidebarInfos = merge(this.tenantInfos, this.job);
              });
          }

          // when rotatedDocUrl is present, use it as default
          if (this.rotatedDocUrl) {
            this.toggleRotatedDocUrl();
          } else {
            this.pdfUrl = '';
            setTimeout(() => {
              this.pdfUrl = escape(this.job.docUrl);
            }, 100);
          }
        },
        error: (error) => {
          if (error.status === 404) {
            this.noJobLeft = true;
            this.job = null;
          }
        },
      });
  }

  get hasTransactions() {
    return true;
  }

  openSubmitModal() {
    this.forceQA = false;
    this.ngxModalService.addModal(SubmitModalComponent).subscribe((result) => {
      if (result) this.submit();
    });
  }

  openSubmitModalQA() {
    this.forceQA = true;
    this.ngxModalService.addModal(SubmitModalComponent).subscribe((result) => {
      if (result) this.submit();
    });
  }

  unlockBalanceList() {
    this.unlockBalanceListSubscription = this.captureTransactionService
      .unlockBalanceList(this.job.id, this.isQualityAssurance)
      .subscribe((res) => {
        this.router.navigate([Routes.Home]);
      });
  }

  renderNextJob() {
    this.route.params.subscribe((r) => {
      if (r['id']) {
        this.router.navigate([
          this.isQualityAssurance
            ? Routes.CaptureTransactionQA
            : Routes.CaptureTransaction,
        ]);
      } else {
        this.renderJob();
      }
    });
  }

  submit() {
    const data = this.captureTransactionService.prepareDataForSubmission(
      this.directDebits,
      this.receivedPayments,
      this.standingOrders,
      this.job,
      this.isQualityAssurance,
      this.approvedWithCorrections,
      this.extractionAccepted,
      this.forceQA,
    );

    this.submitSubscription = this.captureTransactionService
      .submitJob(this.job.id, data, this.isQualityAssurance)
      .subscribe(
        (res) => {
          this.ngxModalService.removeAll();

          if (this.dontRenderNextJob) {
            this.noJobLeft = true;
            return;
          }
          this.renderNextJob();
        },
        (resp: HttpErrorResponse) => {
          if (resp.status === 422 && resp.error.invalidCreditorIds != null) {
            alert(
              'Found invalid creditor IDs: ' + resp.error.invalidCreditorIds,
            );
          }
        },
      );
  }

  sendToAmetras() {
    this.extractionAccepted = false;
    this.submit();
  }

  selectMode(mode: string) {
    this.selectedMode = mode;
  }

  public pdfViewerPageChange(count: number) {
    this.currentPage = count - 1;
  }

  get isSubmitLoading() {
    return !this.submitSubscription.closed;
  }

  get isPremiumBank() {
    return ['VB_DORTMUND', 'VR_FORCHHEIM'].includes(this.job?.tenant);
  }

  get premiumDocUrl() {
    return `${window.location.href}${this.job.id}`;
  }
}
