<form [formGroup]="form">
  <fino-input formControlName="name" label="name">
    <fino-validation-indicator></fino-validation-indicator>
  </fino-input>

  <fino-input formControlName="purpose" label="purpose">
    <fino-validation-indicator></fino-validation-indicator>
  </fino-input>

  <button
    type="submit"
    fino-button
    (click)="submit()"
    [disabled]="!isFormValid"
    [primary]="true"
    class="mb-3"
  >
    <span *ngIf="isUpdateMode" translate="receivedPayments.update"></span>
    <span *ngIf="!isUpdateMode" translate="receivedPayments.add"></span>
  </button>

  <div *ngIf="hasReceivedPayments" class="columns" [formGroup]="searchForm">
    <div class="column is-8">
      <fino-input formControlName="search" class="mr-2" label="Suchen">
      </fino-input>
    </div>
    <div class="column">
      <button class="mt-1 is-fullwidth" fino-button (click)="deleteAllItems()">
        <label class="mr-1" translate="receivedPayments.all"></label>
        <fa-icon [icon]="faTrash"></fa-icon>
      </button>
    </div>
  </div>

  <div class="items-container" *ngIf="hasReceivedPayments">
    <article
      *ngFor="let recievedPayment of filteredReceivedPayments"
      [class.is-primary]="recievedPayment.selected"
      class="panel"
    >
      <div class="panel-heading">
        <div class="columns">
          <div class="column">
            <p class="has-text-white">{{ recievedPayment.name }}</p>
          </div>
          <div class="column is-flex is-justify-content-flex-end">
            <fa-icon
              [icon]="faEdit"
              (click)="editItem(recievedPayment)"
              class="has-text-white mr-3"
            ></fa-icon>
            <fa-icon
              [icon]="faCopy"
              (click)="duplicateItem(recievedPayment)"
              class="has-text-white mr-3"
            ></fa-icon>
            <fa-icon
              [icon]="faTrash"
              (click)="deleteItem(recievedPayment, !recievedPayment.selected)"
              class="has-text-white"
            ></fa-icon>
          </div>
        </div>
      </div>

      <div
        class="panel-block is-flex is-flex-direction-column is-align-items-flex-start"
        [class.has-text-grey-light]="!recievedPayment.selected"
      >
        <label
          ><span translate="receivedPayments.purpose"></span>
          <b>{{ recievedPayment.purpose }}</b></label
        >
      </div>
    </article>
  </div>
</form>
