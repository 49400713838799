import { Injectable } from '@angular/core';
import { Version } from '@version';

export const ENVIRONMENTS_URL_MAPPING = {
  LOCAL: 'localhost',
  DEV: 'dev',
  TEST: 'test',
  PRODUCTION: '',
};

export const ENVIRONMENTS = {
  DEV: 'DEV',
  TEST: 'TEST',
  PRODUCTION: 'PRODUCTION',
  LOCAL: 'LOCAL',
};

@Injectable({
  providedIn: 'root',
})
export class EnvironmentService {
  constructor() {}

  public get version() {
    return Version.version;
  }

  public get isLocal() {
    return ENVIRONMENTS.LOCAL == this.env;
  }

  public get env(): string {
    const url = window.location.host as string;
    if (url.includes(ENVIRONMENTS_URL_MAPPING.DEV)) {
      return ENVIRONMENTS.DEV;
    } else if (url.includes(ENVIRONMENTS_URL_MAPPING.TEST)) {
      return ENVIRONMENTS.TEST;
    } else if (url.includes(ENVIRONMENTS_URL_MAPPING.LOCAL)) {
      return ENVIRONMENTS.LOCAL;
    }

    return ENVIRONMENTS.PRODUCTION;
  }
}
