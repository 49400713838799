"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.hasBranchCode = exports.getBranchCode = exports.getLocationCode = exports.getCountryCode = exports.getBankCode = exports.validate = void 0;
var exceptions_1 = require("./exceptions");
var country_1 = require("./country");
var BIC8_LENGTH = 8;
var BIC11_LENGTH = 11;
var BANK_CODE_INDEX = 0;
var BANK_CODE_LENGTH = 4;
var COUNTRY_CODE_INDEX = BANK_CODE_INDEX + BANK_CODE_LENGTH;
var COUNTRY_CODE_LENGTH = 2;
var LOCATION_CODE_INDEX = COUNTRY_CODE_INDEX + COUNTRY_CODE_LENGTH;
var LOCATION_CODE_LENGTH = 2;
var BRANCH_CODE_INDEX = LOCATION_CODE_INDEX + LOCATION_CODE_LENGTH;
var BRANCH_CODE_LENGTH = 3;
var ucRegex = /^[A-Z]+$/;
var ucnumRegex = /^[A-Z0-9]+$/;
function validate(bic) {
  validateEmpty(bic);
  validateLength(bic);
  validateCase(bic);
  validateBankCode(bic);
  validateCountryCode(bic);
  validateLocationCode(bic);
  if (hasBranchCode(bic)) {
    validateBranchCode(bic);
  }
}
exports.validate = validate;
function validateEmpty(bic) {
  if (bic == null) {
    throw new exceptions_1.FormatException(exceptions_1.FormatViolation.NOT_NULL, "Null can't be a valid Bic.");
  }
  if (bic.length === 0) {
    throw new exceptions_1.FormatException(exceptions_1.FormatViolation.NOT_EMPTY, "Empty string can't be a valid Bic.");
  }
}
function validateLength(bic) {
  if (bic.length !== BIC8_LENGTH && bic.length !== BIC11_LENGTH) {
    throw new exceptions_1.FormatException(exceptions_1.FormatViolation.BIC_LENGTH_8_OR_11, "Bic length must be " + BIC8_LENGTH + " or " + BIC11_LENGTH);
  }
}
function validateCase(bic) {
  if (bic !== bic.toUpperCase()) {
    throw new exceptions_1.FormatException(exceptions_1.FormatViolation.BIC_ONLY_UPPER_CASE_LETTERS, "Bic must contain only upper case letters.");
  }
}
function validateBankCode(bic) {
  var bankCode = getBankCode(bic);
  if (!ucRegex.test(bankCode)) {
    throw new exceptions_1.FormatException(exceptions_1.FormatViolation.BANK_CODE_ONLY_LETTERS, "Bank code must contain only letters.", bankCode);
  }
}
function validateCountryCode(bic) {
  var countryCode = getCountryCode(bic).trim();
  if (countryCode.length < COUNTRY_CODE_LENGTH || countryCode !== countryCode.toUpperCase() || !ucRegex.test(countryCode)) {
    throw new exceptions_1.FormatException(exceptions_1.FormatViolation.COUNTRY_CODE_ONLY_UPPER_CASE_LETTERS, "Bic country code must contain upper case letters", countryCode);
  }
  if (country_1.countryByCode(countryCode) == null) {
    throw new exceptions_1.UnsupportedCountryException("Country code is not supported.", countryCode);
  }
}
function validateLocationCode(bic) {
  var locationCode = getLocationCode(bic);
  if (!ucnumRegex.test(locationCode)) {
    throw new exceptions_1.FormatException(exceptions_1.FormatViolation.LOCATION_CODE_ONLY_LETTERS_OR_DIGITS, "Location code must contain only letters or digits.", locationCode);
  }
}
function validateBranchCode(bic) {
  var branchCode = getBranchCode(bic);
  if (!ucnumRegex.test(branchCode)) {
    throw new exceptions_1.FormatException(exceptions_1.FormatViolation.BRANCH_CODE_ONLY_LETTERS_OR_DIGITS, "Branch code must contain only letters or digits.", branchCode);
  }
}
function getBankCode(bic) {
  return bic.substring(BANK_CODE_INDEX, BANK_CODE_INDEX + BANK_CODE_LENGTH);
}
exports.getBankCode = getBankCode;
function getCountryCode(bic) {
  return bic.substring(COUNTRY_CODE_INDEX, COUNTRY_CODE_INDEX + COUNTRY_CODE_LENGTH);
}
exports.getCountryCode = getCountryCode;
function getLocationCode(bic) {
  return bic.substring(LOCATION_CODE_INDEX, LOCATION_CODE_INDEX + LOCATION_CODE_LENGTH);
}
exports.getLocationCode = getLocationCode;
function getBranchCode(bic) {
  return bic.substring(BRANCH_CODE_INDEX, BRANCH_CODE_INDEX + BRANCH_CODE_LENGTH);
}
exports.getBranchCode = getBranchCode;
function hasBranchCode(bic) {
  return bic.length === BIC11_LENGTH;
}
exports.hasBranchCode = hasBranchCode;
