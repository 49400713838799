import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { IdentifyDocumentService } from '../identify-document/identify-document.service';
import {
  CdkDragDrop,
  moveItemInArray,
  CdkDropList,
  CdkDrag,
} from '@angular/cdk/drag-drop';
import {
  faLock,
  faEnvelopeOpen,
  faCopy,
} from '@fortawesome/free-solid-svg-icons';
import { filter, map } from 'lodash';
import { SiblingsService } from './siblings.service';
import { Routes } from 'src/app/core/routes/routes';
import { SubmittableForm } from '@fino-ui/forms';
import {
  FormControl,
  Validators,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { Subscription } from 'rxjs';
import { ClipboardService } from 'ngx-clipboard';
import { NgIf, NgFor, DatePipe } from '@angular/common';
import { PdfJsViewerModule } from 'ng2-pdfjs-viewer';
import { FinoCommonModule } from '@fino-ui/common';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { ButtonModule } from '@fino-ui/button';
import { InputModule } from '@fino-ui/input';
import { FinancesCommonModule } from '@fino-ui/finances';
import { FinoTranslationModule } from '@fino-ui/translation';

const SIBLINGS_TYPES = {
  DOCUMENT_TYPE_RELEVANT: 'relevant',
  DOCUMENT_TYPE_NOT_RELEVANT: 'notRelevant',
  DOCUMENT_TYPE_DUPLICAT: 'duplicat',
};

@Component({
  selector: 'app-siblings',
  templateUrl: './siblings.component.html',
  styleUrls: ['./siblings.component.sass'],
  imports: [
    NgIf,
    PdfJsViewerModule,
    FinoTranslationModule,
    CdkDropList,
    NgFor,
    CdkDrag,
    FinoCommonModule,
    FaIconComponent,
    FormsModule,
    ButtonModule,
    ReactiveFormsModule,
    InputModule,
    DatePipe,
    FinancesCommonModule,
  ],
})
export class SiblingsComponent implements OnInit {
  constructor(
    private identifyDocumentService: IdentifyDocumentService,
    private route: ActivatedRoute,
    private router: Router,
    private siblingsService: SiblingsService,
    private clipboardService: ClipboardService,
  ) {}

  public faLock = faLock;
  public faCopy = faCopy;
  public faEnvelopeOpen = faEnvelopeOpen;
  public SIBLINGS_TYPES = SIBLINGS_TYPES;

  public siblings: any;
  public originalDocument: any;
  public originalPdfUrl: string = '';
  public siblingPdfUrl: string = '';
  public selectedJobId: string = '';
  public previewUrl: string = '';
  public showPreview: boolean = false;
  public documentsToMerge: string[] = [];
  public documentsToDismiss: string[] = [];
  public mergeButtonActive: boolean = false;

  mergeSiblingForm = new SubmittableForm({
    documentsChecked: new FormControl(false, Validators.required),
  });

  mergeSubscription: Subscription = Subscription.EMPTY;

  drop(event: CdkDragDrop<any[]>) {
    moveItemInArray(this.siblings, event.previousIndex, event.currentIndex);
  }

  selectJob(job: any) {
    const { id } = job;
    if (id == this.selectedJobId) return;
    this.siblingPdfUrl = '';
    this.selectedJobId = job.id;
    setTimeout(() => {
      (this.siblingPdfUrl = escape(job.docUrl)), 100;
    });
  }

  selectSiblingState($event: any) {
    $event.preventDefault();
  }

  createPreview() {
    this.documentsToMerge = map(
      filter(
        this.siblings,
        (s) => s.mergeSiblingState === SIBLINGS_TYPES.DOCUMENT_TYPE_RELEVANT,
      ),
      (s) => s.id,
    );
    this.documentsToDismiss = map(
      filter(
        this.siblings,
        (s) => s.mergeSiblingState === SIBLINGS_TYPES.DOCUMENT_TYPE_DUPLICAT,
      ),
      (s) => s.id,
    );

    this.mergeSubscription = this.siblingsService
      .createPreview(this.documentsToDismiss, this.documentsToMerge)
      .subscribe((resp: any) => {
        this.showPreview = true;
        this.previewUrl = escape(resp.preview.links.seeOther);
      });
  }

  abortMerge() {
    this.router.navigate([Routes.IdentifyDocument, this.originalDocument.id]);
  }

  copyDocumentId(documentId: string, $event: any) {
    $event.preventDefault();
    $event.stopPropagation();
    this.clipboardService.copy(documentId);
  }

  abortPreview() {
    this.showPreview = false;
  }

  mergeDocuments() {
    this.mergeSubscription = this.siblingsService
      .merge(this.documentsToDismiss, this.documentsToMerge)
      .subscribe((resp: any) => {
        this.router.navigate([Routes.IdentifyDocument, resp.id]);
      });
  }

  ngOnInit(): void {
    this.route.params.subscribe((r) => {
      this.mergeSiblingForm.controls['documentsChecked'].valueChanges.subscribe(
        (change) => {
          this.mergeButtonActive = change;
        },
      );

      this.identifyDocumentService
        .fetchNextJob(r['id'])
        .subscribe((originalDocument: any) => {
          this.originalPdfUrl = escape(originalDocument.docUrl);
          this.originalDocument = originalDocument;
          this.originalDocument.isOriginal = true;

          this.identifyDocumentService
            .getSiblings(r['id'])
            .subscribe((siblings) => {
              this.siblings = [this.originalDocument, ...(siblings as [])];
              map(
                this.siblings,
                (s) =>
                  (s.mergeSiblingState =
                    this.SIBLINGS_TYPES.DOCUMENT_TYPE_NOT_RELEVANT),
              );

              this.siblings[0].mergeSiblingState =
                this.SIBLINGS_TYPES.DOCUMENT_TYPE_RELEVANT;

              this.siblingPdfUrl = escape(this.originalDocument.docUrl);
            });
        });
    });
  }
}
