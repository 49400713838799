import { ThemingService } from '@fino-ui/theming';
import { FinoTranslationConfig } from '@fino-ui/translation';

export function initTranslationConfig(themingService: ThemingService) {
  const translationConfig = {
    availableLanguages: ['de-DE', 'en-EN'],
    defaultLanguage: 'de-DE',
  };

  return translationConfig;
}

export const provideTranslationConfig = () => ({
  provide: FinoTranslationConfig,
  useFactory: initTranslationConfig,
  deps: [ThemingService],
});
