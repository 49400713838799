export const COMMENT_PRESETS = [
  {
    label: 'MISSING SALDENLISTE ERMÄCHTIGUNG ONLY',
    value: 'MISSING SALDENLISTE ERMÄCHTIGUNG ONLY',
  },
  {
    label: 'DOCUMENT WITH 2 SALDENLISTE WITH 2 DIFFERENT KONTOINHABER AND IBAN',
    value: 'DOCUMENT WITH 2 SALDENLISTE WITH 2 DIFFERENT KONTOINHABER AND IBAN',
  },
  {
    label: 'MISSING SALDENLISTE - OTHER DOCUMENTS',
    value: 'MISSING SALDENLISTE - OTHER DOCUMENTS',
  },
  {
    label: 'MISSING IBAN',
    value: 'MISSING IBAN',
  },
  {
    label: 'WRONG IBAN',
    value: 'WRONG IBAN',
  },
  {
    label: 'BAD SCAN QUALITY',
    value: 'BAD SCAN QUALITY',
  },
];

export const DOCUMENT_STATUS = {
  OPEN: 'open',
  CLOSED: 'closed',
};
