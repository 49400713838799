import { Component, OnInit } from '@angular/core';
import {
  FormControl,
  Validators,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { SubmittableForm } from '@fino-ui/forms';
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import { merge } from 'lodash';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import { NavbarService } from 'src/app/components/navbar/navbar.service';
import { Routes } from 'src/app/core/routes/routes';
import { germanDateValidator } from 'src/app/core/validators/germanDate.validator';
import { COMMENT_PRESETS, WARRANT_FIELDS } from './capture-warrant.constants';
import { CaptureWarrantService } from './capture-warrant.service';
import { EnvironmentService } from 'src/app/core/environment/environment.service';
import { reportForm } from '../capture-transaction/capture-transaction.form.constants';
import { NgIf, NgFor } from '@angular/common';
import { PdfJsViewerModule } from 'ng2-pdfjs-viewer';
import { IbanCheckComponent } from '../../components/iban-check/iban-check.component';
import { FinoCommonModule } from '@fino-ui/common';
import { SelectModule } from '@fino-ui/select';
import { InputModule } from '@fino-ui/input';
import { ButtonModule } from '@fino-ui/button';
import { FinancesCommonModule } from '@fino-ui/finances';
import { FinoTranslationModule } from '@fino-ui/translation';

@Component({
  selector: 'app-capture-warrant',
  templateUrl: './capture-warrant.component.html',
  styleUrls: ['./capture-warrant.component.sass'],
  imports: [
    NgIf,
    PdfJsViewerModule,
    FormsModule,
    ReactiveFormsModule,
    FinoTranslationModule,
    IbanCheckComponent,
    SelectModule,
    InputModule,
    ButtonModule,
    FinoCommonModule,
    NgFor,
    FinancesCommonModule,
  ],
})
export class CaptureWarrantComponent implements OnInit {
  constructor(
    private route: ActivatedRoute,
    private captureWarrantService: CaptureWarrantService,
    private navbarService: NavbarService,
    private router: Router,
    private envService: EnvironmentService,
  ) {}

  public errorMessage: string = '';
  public noJobLeft: boolean = false;
  public showIbanCheck: boolean = true;
  public job: any;
  public pdfUrl: string = '';
  public submitSubscription: Subscription = Subscription.EMPTY;

  public warrantFields = WARRANT_FIELDS;

  public faTrash = faTrash;
  public faEdit = faEdit;
  public showAccountClosingDate: boolean = false;
  public hasSecondAccountOwner: boolean = false;

  public reportForm: SubmittableForm = reportForm;

  public reportSubscription: Subscription = Subscription.EMPTY;

  public dontRenderNextJob: boolean = false;

  public loadNextDocumentForm: SubmittableForm = new SubmittableForm({
    loadNext: new FormControl(true),
  });

  public reportPresetItems = COMMENT_PRESETS;

  get isLocal() {
    return this.envService.isLocal;
  }

  get reportDocumentLoading() {
    return !this.reportSubscription?.closed;
  }

  ngOnInit(): void {
    this.route.params.subscribe((r) => {
      this.renderJob(r['id']);
    });

    this.loadNextDocumentForm.valueChanges.subscribe((r) => {
      const { loadNext } = r;
      this.dontRenderNextJob = !loadNext;
    });

    this.jobForm.controls['closeAccount'].valueChanges.subscribe((r) => {
      const accountClosingDateControll =
        this.jobForm.controls['accountClosingDate'];
      accountClosingDateControll.setValidators(
        r ? [Validators.required, germanDateValidator] : [],
      );
      accountClosingDateControll.updateValueAndValidity();
      this.showAccountClosingDate = r;
    });

    this.reportForm.controls['preset']?.valueChanges.subscribe((change) => {
      this.reportForm.patchValue({
        comment: change,
      });
    });
  }

  get jobFormValid() {
    return this.jobForm.valid;
  }

  public jobForm: SubmittableForm = new SubmittableForm({
    accountOwnerOneFirstName: new FormControl('', [Validators.required]),
    accountOwnerOneLastName: new FormControl('', Validators.required),
    accountOwnerTwoFirstName: new FormControl(''),
    accountOwnerTwoLastName: new FormControl(''),
    transferCredits: new FormControl(null, [Validators.required]),
    transferDebits: new FormControl(null, [Validators.required]),
    transferStandingOrders: new FormControl(null, [Validators.required]),
    transferBalance: new FormControl(null, [Validators.required]),
    closeAccount: new FormControl(null, [Validators.required]),
    accountClosingDate: new FormControl('', []),
  });

  renderNextJob() {
    this.errorMessage = '';
    this.route.params.subscribe((r) => {
      if (r['id']) {
        this.router.navigate([Routes.CaptureWarrant]);
      } else {
        this.renderJob();
      }
    });
  }

  ibanCheckSuccess($event: boolean) {
    this.showIbanCheck = !$event;
  }

  renderJob(id: string = '') {
    this.jobForm.reset();
    this.errorMessage = '';
    this.captureWarrantService.fetchNextJob(id).subscribe({
      next: (result: any) => {
        this.navbarService.loadCounts.next('captureWarrant');
        this.showAccountClosingDate = false;
        this.hasSecondAccountOwner = false;
        this.noJobLeft = false;
        this.job = result;
        this.showIbanCheck = true;

        this.pdfUrl = '';
        setTimeout(() => {
          this.pdfUrl = escape(this.job.docUrl);
        }, 100);
      },
      error: (result: any) => {
        if (result.status == 404) {
          this.noJobLeft = true;
        }
      },
    });
  }

  reportDocument() {
    this.reportSubscription = this.captureWarrantService
      .reportDocument(this.job.id, this.reportForm.controls['comment'].value)
      .subscribe(() => {
        this.reportForm.reset();
        this.renderNextJob();
      });
  }

  toggleSecondAccountOwner() {
    this.hasSecondAccountOwner = !this.hasSecondAccountOwner;
  }

  getAccountOwners() {
    const {
      accountOwnerOneFirstName,
      accountOwnerOneLastName,
      accountOwnerTwoFirstName,
      accountOwnerTwoLastName,
    } = this.jobForm.value;

    const accountOwners = [
      {
        firstName: accountOwnerOneFirstName,
        lastName: accountOwnerOneLastName,
      },
    ];

    if (this.hasSecondAccountOwner) {
      accountOwners.push({
        firstName: accountOwnerTwoFirstName,
        lastName: accountOwnerTwoLastName,
      });
    }

    return accountOwners;
  }

  submitJob() {
    this.jobForm.markAllAsTouched();
    if (this.jobFormValid) {
      const {
        closeAccount,
        transferBalance,
        transferCredits,
        transferDebits,
        transferStandingOrders,
        accountClosingDate,
      } = this.jobForm.value;

      const accountOwners = this.getAccountOwners();

      let toBeSubmitted = {
        accountOwners,
        closeAccount,
        transferBalance,
        transferCredits,
        transferDebits,
        transferStandingOrders,
      };
      const closingDate = moment
        .utc(accountClosingDate, 'DD.MM.YYYY', 'de')
        .toISOString();

      if (accountClosingDate) {
        toBeSubmitted = merge(toBeSubmitted, {
          accountClosingDate: closingDate,
        });
      }

      this.submitSubscription = this.captureWarrantService
        .submitJob(this.job.id, toBeSubmitted)
        .subscribe({
          next: (r) => {
            if (this.dontRenderNextJob) {
              this.noJobLeft = true;
              return;
            }
            this.renderNextJob();
          },
          error: (err) => {
            this.errorMessage = err.message;
          },
        });
    }
  }
}
