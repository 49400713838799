import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Api } from 'src/app/core/api/Api';

@Injectable({
  providedIn: 'root',
})
export class ClarificationService {
  constructor(private http: HttpClient) {}

  loadClarificationJobs() {
    return this.http.get(Api.Clarification);
  }
}
