export const chart: Highcharts.Options = {
  title: {
    text: '',
  },
  chart: {
    type: 'column',
  },
  yAxis: {
    title: { text: '' },
  },
  xAxis: {
    title: { text: '' },
    labels: {
      enabled: false,
    },
  },
  tooltip: {
    useHTML: true,
    enabled: true,
  },
  credits: {
    enabled: false,
  },
  legend: {
    enabled: true,
  },
  plotOptions: {
    series: {
      events: {},
    },
  },
};
