import { Component, OnInit } from '@angular/core';
import { merge } from 'lodash';
import { Subscription } from 'rxjs';
import { NavbarService } from 'src/app/components/navbar/navbar.service';
import { IdentifyDocumentService } from '../identify-document/identify-document.service';
import { ClarificationService } from './clarification.service';
import { NgIf, NgFor } from '@angular/common';
import { FinoCommonModule } from '@fino-ui/common';
import { IdentifyDocumentComponent } from '../identify-document/identify-document.component';
import { FinoTranslationModule } from '@fino-ui/translation';

@Component({
  selector: 'app-clarification',
  templateUrl: './clarification.component.html',
  styleUrls: ['./clarification.component.sass'],
  imports: [
    NgIf,
    NgFor,
    FinoCommonModule,
    IdentifyDocumentComponent,
    FinoTranslationModule,
  ],
})
export class ClarificationComponent implements OnInit {
  constructor(
    private clarificationService: ClarificationService,
    private identifyDocumentService: IdentifyDocumentService,
    private navbarService: NavbarService,
  ) {}

  public clarificationJobs: any[] = [];
  public selectedJob: any;
  public selectedJobId: string = '';
  public selectedJobPreviousJobId: string = '';
  public jobSubscription: Subscription = Subscription.EMPTY;
  public clarificationSubscription: Subscription = Subscription.EMPTY;

  public isUpgrightDisplay: boolean = false;

  ngOnInit(): void {
    this.loadClarificationJobs();

    this.navbarService.toggleUpright.subscribe((toggleUpright) => {
      this.isUpgrightDisplay = toggleUpright;
    });
  }

  loadClarificationJobs() {
    this.clarificationSubscription = this.clarificationService
      .loadClarificationJobs()
      .subscribe((res) => {
        this.clarificationJobs = res as any[];
      });
  }

  selectJob(job: any) {
    this.selectedJobId = job.id;
    this.selectedJobPreviousJobId = job.previousJobId;
    this.jobSubscription = this.identifyDocumentService
      .fetchNextJob(job.previousJobId)

      .subscribe({
        next: (result) => {
          if (!result) {
            this.selectedJob = merge(job, {
              envelope: {
                tenant: job.tenant,
                documentType: '',
                oldIban: job.oldIban,
                newIban: job.newIban,
                rejectionReason: '',
                rejectionReasonText: '',
              },
            });
            return;
          }
          this.selectedJob = result;
        },
      });
  }
}
