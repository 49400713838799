import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { SubmittableForm } from '@fino-ui/forms';

import { faEdit, faTrash, faCopy } from '@fortawesome/free-solid-svg-icons';
import { clone, cloneDeep, filter, includes, map, merge, pick } from 'lodash';
import { updateOrAdd } from 'src/app/core/helpers/helpers';
import { RECEIVED_PAYMENTS_FIELDS } from './recieved-payments.constants';
import { v4 as uuidv4 } from 'uuid';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FinoCommonModule } from '@fino-ui/common';
import { NgIf, NgFor } from '@angular/common';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { InputModule } from '@fino-ui/input';
import { ButtonModule } from '@fino-ui/button';
import { FinoTranslationModule } from '@fino-ui/translation';

@Component({
  selector: 'app-recieved-payments',
  templateUrl: './recieved-payments.component.html',
  styleUrls: ['./recieved-payments.component.sass'],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    InputModule,
    ButtonModule,
    FinoCommonModule,
    NgIf,
    FinoTranslationModule,
    FaIconComponent,
    NgFor,
  ],
})
export class ReceivedPaymentsComponent implements OnInit, OnChanges {
  faEdit = faEdit;
  faTrash = faTrash;
  faCopy = faCopy;

  @Input()
  public form: SubmittableForm = new SubmittableForm({});

  @Input()
  public receivedPayments: any[] = [];

  @Output() receivedPaymentsSubmitted = new EventEmitter<any>();

  isUpdateMode: boolean = false;

  public toggleAllDelete: boolean = true;
  public receivedPaymentsFields = RECEIVED_PAYMENTS_FIELDS;

  public searchForm: SubmittableForm = new SubmittableForm({
    search: new FormControl('', []),
  });

  public filteredReceivedPayments: any[] = [];

  constructor() {}

  ngOnChanges(changes: SimpleChanges): void {
    const { receivedPayments } = changes;
    if (receivedPayments) {
      this.filteredReceivedPayments = clone(this.receivedPayments);
    }
  }

  submit() {
    this.form.markAllAsTouched();
    if (this.form.valid) {
      const toBeAdded = this.form.value;
      if (!toBeAdded.id) {
        toBeAdded.id = uuidv4();
      }
      this.isUpdateMode = false;
      updateOrAdd(
        this.receivedPayments,
        merge(toBeAdded, { selected: true }),
        'id',
      );
      this.filteredReceivedPayments = clone(this.receivedPayments);
      this.searchForm.reset();

      this.receivedPaymentsSubmitted.next(this.form.value);
      this.form.reset();
    }
  }

  get hasReceivedPayments() {
    return this.receivedPayments && this.receivedPayments.length > 0;
  }

  duplicateItem(item: any) {
    const cloned = cloneDeep(item);
    cloned.id = uuidv4();
    this.form.patchValue(cloned);
  }

  deleteItem(item: any, selected: boolean = true) {
    item.selected = selected;
    this.receivedPaymentsSubmitted.next(this.form.value);
  }

  deleteAllItems() {
    this.toggleAllDelete = !this.toggleAllDelete;
    map(this.receivedPayments, (d) => this.deleteItem(d, this.toggleAllDelete));
  }

  editItem(item: any) {
    this.isUpdateMode = true;
    this.form.patchValue(pick(item, this.receivedPaymentsFields));
  }

  get isFormValid() {
    return this.form.valid;
  }

  setupFilterForm() {
    this.searchForm.controls['search'].valueChanges.subscribe(
      (keyword: string) => {
        if (!keyword) {
          this.filteredReceivedPayments = clone(this.receivedPayments);
          return;
        }
        this.filteredReceivedPayments = filter(this.receivedPayments, (item) =>
          item.name.toLowerCase().includes(keyword.toLowerCase()),
        );
      },
    );
  }

  ngOnInit(): void {
    this.setupFilterForm();
  }
}
