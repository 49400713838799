<div class="columns" *ngIf="!showPreview">
  <div class="column darkmode-ignore">
    <ng2-pdfjs-viewer
      *ngIf="originalPdfUrl"
      pagemode="thumbs"
      viewerId="JobPdfViewer"
      [pdfSrc]="originalPdfUrl"
    ></ng2-pdfjs-viewer>
  </div>
  <div class="column">
    <div class="flex is-justify-content-flex-end">
      <label
        class="p-1 has-text-weight-bold"
        translate="siblings.notRelevant"
      ></label>
      <label
        class="p-1 has-text-weight-bold"
        translate="siblings.relevant"
      ></label>
      <label
        class="p-1 has-text-weight-bold"
        translate="siblings.duplicate"
      ></label>
    </div>
    <div cdkDropList class="siblings-list" (cdkDropListDropped)="drop($event)">
      <div
        class="siblings-box flex"
        [class.has-background-grey]="sibling.isOriginal"
        [class.has-background-grey-lighter]="sibling.id == selectedJobId"
        *ngFor="let sibling of siblings"
        (click)="selectJob(sibling)"
        cdkDrag
      >
        <div class="flex p-4">
          <div class="siblings-info-container">
            <div class="flex">
              <b
                *ngIf="sibling.isOriginal"
                translate="siblings.originalDocument"
              ></b>
              <b
                *ngIf="!sibling.isOriginal"
                translate="siblings.furtherDocument"
              ></b>
              <fa-icon
                *ngIf="sibling.status === 'locked'"
                size="lg"
                [icon]="faLock"
                class="has-text-danger ml-4"
              ></fa-icon>

              <fa-icon
                size="lg"
                *ngIf="sibling.status === 'open'"
                [icon]="faEnvelopeOpen"
                class="has-text-primary ml-4"
              ></fa-icon>

              <small class="pt-1 pl-2" *ngIf="sibling.statusChangedBy">
                {{ sibling.statusChangedBy }}</small
              >
            </div>

            <p
              *ngIf="sibling.envelope.documentType"
              class="has-text-weight-bold has-text-orange"
            >
              {{ "documentType." + sibling.envelope.documentType | translate }}
            </p>
            <p
              *ngIf="sibling.envelope.rejectionReason"
              class="has-text-weight-bold"
              [class.has-text-danger]="
                sibling.envelope.rejectionReason !== 'OK'
              "
              [class.has-text-success]="
                sibling.envelope.rejectionReason === 'OK'
              "
            >
              {{ sibling.envelope.rejectionReason | translate }}
            </p>
            <p>
              {{ sibling.envelope.received | date: "dd.MM.yyyy hh:mm:ss" }}
            </p>
            <p>{{ sibling.envelope.sender }}</p>
            <p class="is-flex" (click)="copyDocumentId(sibling.id, $event)">
              ID: {{ sibling.id }}
              <fa-icon [icon]="faCopy" class="has-text-primary ml-3"></fa-icon>
            </p>
          </div>

          <div class="siblings-state-container">
            <div class="control flex is-justify-content-space-between">
              <input
                type="radio"
                [(ngModel)]="sibling.mergeSiblingState"
                [name]="sibling.id"
                [value]="SIBLINGS_TYPES.DOCUMENT_TYPE_NOT_RELEVANT"
              />
              <input
                type="radio"
                [(ngModel)]="sibling.mergeSiblingState"
                [name]="sibling.id"
                [value]="SIBLINGS_TYPES.DOCUMENT_TYPE_RELEVANT"
                checked
              />
              <input
                type="radio"
                [(ngModel)]="sibling.mergeSiblingState"
                [name]="sibling.id"
                [value]="SIBLINGS_TYPES.DOCUMENT_TYPE_DUPLICAT"
                checked
              />
            </div>

            <div class="additional-info-container">
              <small
                *ngIf="sibling.envelope.newIban"
                translate="siblings.newIban"
                [translateParams]="{ iban: sibling.envelope.newIban | iban }"
              ></small
              ><br />
              <small
                *ngIf="sibling.envelope.oldIban"
                translate="siblings.oldIban"
                [translateParams]="{ iban: sibling.envelope.oldIban | iban }"
              >
              </small>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="column darkmode-ignore">
    <ng2-pdfjs-viewer
      *ngIf="siblingPdfUrl"
      pagemode="thumbs"
      viewerId="SiblingPdfViewer"
      [pdfSrc]="siblingPdfUrl"
    ></ng2-pdfjs-viewer>

    <fino-button-row>
      <button
        [primary]="true"
        [loading]="!mergeSubscription.closed"
        (click)="createPreview()"
        fino-button
        translate="siblings.preview"
      ></button>
      <button
        type="button"
        (click)="abortMerge()"
        translate="siblings.abort"
        fino-button
      ></button>
    </fino-button-row>
  </div>
</div>

<div class="columns" *ngIf="showPreview">
  <div class="column darkmode-ignore">
    <ng2-pdfjs-viewer
      *ngIf="previewUrl"
      viewerId="PreviewPdfViewer"
      [pdfSrc]="previewUrl"
    ></ng2-pdfjs-viewer>
  </div>
  <form [formGroup]="mergeSiblingForm" class="column">
    <div *ngFor="let sibling of siblings">
      <article
        *ngIf="
          sibling.mergeSiblingState !==
          SIBLINGS_TYPES.DOCUMENT_TYPE_NOT_RELEVANT
        "
        class="panel is-primary"
        [class.has-background-primary]="sibling.isOriginal"
      >
        <div class="panel-block flex is-flex-direction-column">
          <b
            *ngIf="sibling.isOriginal"
            translate="siblings.originalDocument"
          ></b>
          <b
            *ngIf="!sibling.isOriginal"
            translate="siblings.furtherDocument"
          ></b>
          <p>{{ sibling.envelope.received | date: "dd.MM.yyyy hh:mm:ss" }}</p>
          <p>{{ sibling.id }}</p>
        </div>
      </article>
    </div>

    <fino-checkbox
      class="mt-2"
      formControlName="documentsChecked"
      label="siblings.documentComplete"
    >
    </fino-checkbox>

    <fino-button-row>
      <button
        [primary]="true"
        [disabled]="!mergeButtonActive"
        [loading]="!mergeSubscription.closed"
        (click)="mergeDocuments()"
        fino-button
        translate="siblings.mergeDocuments"
      ></button>
      <button
        type="button"
        (click)="abortPreview()"
        fino-button
        translate="siblings.abort"
      ></button>
    </fino-button-row>
  </form>
</div>
