<div class="is-flex is-align-items-center is-flex-direction-column">
  <div class="is-fullwidth is-flex is-justify-content-flex-start">
    <div
      class="is-flex options is-flex-direction-column is-justify-content-flex-start ml-4"
      [formGroup]="bankForm"
    >
      <button fino-button (click)="toggleChart()" class="mb-3">
        <fa-icon *ngIf="!hideChart" [icon]="faCircleXmark"></fa-icon>
        <fa-icon *ngIf="hideChart" [icon]="faChartSimple"></fa-icon>
      </button>
      <button fino-button (click)="fetchTickets()" class="mb-3">
        <fa-icon [icon]="faRefresh"></fa-icon>
      </button>
      @if (hasTenants) {
        <div>
          <button
            *ngIf="!showBankList"
            fino-button
            (click)="toggleBankList()"
            class="mb-3"
          >
            <fa-icon [icon]="faBuildingColumns"></fa-icon>
          </button>
          <fino-select
            *ngIf="showBankList"
            class="bank-select has-background-white-bis is-absolute"
            [items]="tenants"
            label="Banken"
            #tenantSelect
            formControlName="bank"
            [searchable]="true"
            bindLabel="displayName"
          >
            <div *select-item="let tenant" (click)="selectBank(tenant)">
              <div>{{ tenant.displayName }}</div>
              <div class="is-size-7 has-text-grey">
                {{ tenant.urlWithoutProtocol }}
              </div>
            </div>
          </fino-select>
        </div>
      }
    </div>

    <div [hidden]="hideChart" class="is-fullwidth">
      <app-ticket-overview-chart
        [statistic]="statistic"
        (legendClicked)="onLegendClick($event)"
      ></app-ticket-overview-chart>
    </div>
  </div>

  <form [formGroup]="searchForm" class="mt-5 mb-5 columns">
    <div class="column">
      <fino-input formControlName="keyword" class="search-input" label="Suchen">
      </fino-input>
    </div>
    <div class="column">
      <fino-checkbox
        formControlName="showOnlyRemindersPaused"
        label="ticketOverview.remindersPaused"
      >
      </fino-checkbox>
      <fino-checkbox
        class="mt-2"
        formControlName="showOnlyOpenStandingOrders"
        label="ticketOverview.hasOpenStandingOrders"
      >
      </fino-checkbox>
    </div>
  </form>

  <app-ticket-table
    [isLoading]="isLoading"
    [sortOrder]="sortOrder"
    [filterProperty]="filterProperty"
    (sortByFieldEvent)="sortByField($event)"
    [tickets]="tickets"
    [organization]="organization"
  ></app-ticket-table>

  <div class="mb-4" *ngIf="!hasTickets && !isLoading">
    <span translate>ticketOverview.noTicketsFound</span>
  </div>

  <div>
    <fa-icon
      *ngIf="isLoading"
      [icon]="faSpinner"
      [spin]="true"
      class="has-text-primary loading-spinner"
    ></fa-icon>
  </div>

  <nav
    *ngIf="hasTickets && !isLoading"
    class="pagination is-rounded my-4"
    role="navigation"
    aria-label="pagination"
  >
    <ul class="pagination-list">
      <li *ngIf="currentPage > 2" (click)="goToPage(1)">
        <a class="pagination-link">1</a>
      </li>
      <li *ngIf="currentPage > 2">
        <span class="pagination-ellipsis">&hellip;</span>
      </li>

      <li *ngIf="currentPage > 1">
        <a class="pagination-link" (click)="previousPage()">{{
          currentPage - 1
        }}</a>
      </li>
      <li>
        <a class="pagination-link is-current">{{ currentPage }}</a>
      </li>
      <li *ngIf="currentPage < lastPage - 1">
        <a class="pagination-link" (click)="nextPage()">{{
          currentPage + 1
        }}</a>
      </li>
      <li *ngIf="currentPage < lastPage - 1">
        <span class="pagination-ellipsis">&hellip;</span>
      </li>
      <li *ngIf="currentPage < lastPage" (click)="goToPage(lastPage)">
        <a class="pagination-link">{{ lastPage }} </a>
      </li>
    </ul>
  </nav>
</div>
