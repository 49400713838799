import { Pipe, PipeTransform } from '@angular/core';
import { IbanPipe } from '@fino-ui/finances';

@Pipe({
    name: 'ibanColor',
    standalone: true,
})
export class IbanColorPipe implements PipeTransform {
  transform(iban: string, blz: string) {
    const ibanPipe = new IbanPipe();
    iban = ibanPipe.transform(iban);

    if (!blz) {
      return iban;
    }
    blz = blz.substring(0, 4) + ' ' + blz.substring(4, 8);
    const splits = iban.split(blz);
    if (splits.length <= 1) return iban;

    let result =
      splits[0] +
      `<span class="has-text-primary has-text-weight-bold">${blz}</span>` +
      splits[1];

    return result;
  }
}
