import {
  HTTP_INTERCEPTORS,
  HttpClient,
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';
import {
  ErrorHandler,
  importProvidersFrom,
  inject,
  provideAppInitializer,
} from '@angular/core';
import { FINO_VERSION } from '@fino-ui/common';
import { Version } from '@version';
import {
  provideNgxWebstorage,
  withLocalStorage,
  withNgxWebstorageConfig,
  withSessionStorage,
} from 'ngx-webstorage';

import { ApplicationConfig } from '@angular/core';
import { provideAnimations } from '@angular/platform-browser/animations';
import {
  provideRouter,
  withComponentInputBinding,
  withRouterConfig,
} from '@angular/router';
import { provideAngularSvgIcon } from 'angular-svg-icon';
import { defaultNgxModalOptions, NgxModalView } from 'ngx-modalview';
import { routes } from './app.routes';
import { KeycloakAngularModule, KeycloakService } from 'keycloak-angular';
import { loadConfig } from './core/environment/config.loader';
import { FinoTranslationModule } from '@fino-ui/translation';
import { provideTranslationConfig } from './core/translation/translation.provider';
import { ToastrModule } from 'ngx-toastr';
import { ngxLoadingAnimationTypes, NgxLoadingModule } from 'ngx-loading';
import { LoadingHttpInterceptor } from './core/interceptors/loading.interceptor';
import { createErrorHandler } from '@sentry/angular-ivy';
import { ThemingService } from '@fino-ui/theming';
import { initilizeTheme } from './core/environment/theme.initializer';
import { provideEnvironmentNgxMask } from 'ngx-mask';

export const appConfig: ApplicationConfig = {
  providers: [
    provideTranslationConfig(),
    importProvidersFrom(
      KeycloakAngularModule,
      FinoTranslationModule.forRoot(),
      NgxModalView.forRoot(
        { container: document.body },
        {
          ...defaultNgxModalOptions,
          ...{
            animationDuration: 200,
            closeOnEscape: true,
            closeOnClickOutside: true,
          },
        },
      ),
      ToastrModule.forRoot(),
      NgxLoadingModule.forRoot({
        animationType: ngxLoadingAnimationTypes.circleSwish,
      }),
    ),
    {
      provide: FINO_VERSION,
      useValue: Version,
    },
    provideAnimations(),
    provideAngularSvgIcon(),
    provideHttpClient(withInterceptorsFromDi()),
    provideNgxWebstorage(
      withNgxWebstorageConfig({
        caseSensitive: true,
        // NOTE: The prefix and separator options lead to the following error:
        // The tenant loadConfig will not work correctly if the prefix or separator is set.
        // It will load the default configuration instead of the tenant-specific configuration.
        // prefix: 'uba',
        // separator: '.',
      }),
      withLocalStorage(),
      withSessionStorage(),
    ),
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoadingHttpInterceptor,
      multi: true,
    },
    {
      provide: ErrorHandler,
      useValue: createErrorHandler({
        showDialog: false,
      }),
    },
    provideTranslationConfig(),
    provideRouter(
      routes,
      withComponentInputBinding(),
      withRouterConfig({
        // Reload the page if the same URL is requested
        onSameUrlNavigation: 'reload',
        // Bind route info to component inputs (e.g. route.params)
        paramsInheritanceStrategy: 'always',
      }),
    ),
    provideEnvironmentNgxMask(),
    provideAppInitializer(() => {
      const initializerFn = loadConfig(
        inject(HttpClient),
        inject(KeycloakService),
      );
      return initializerFn();
    }),
    provideAppInitializer(() => {
      const initializerFn = initilizeTheme(inject(ThemingService));
      return initializerFn();
    }),
  ],
};
