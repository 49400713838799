<div>
  <div class="columns is-gapless mb-1">
    <div class="column">
      <p class="menu-label pl-4 pt-1" translate="tenantInfos.title"></p>
    </div>
    <div
      (click)="toggleExpanded()"
      class="column is-flex is-justify-content-flex-end"
    >
      <!--<fa-icon [fixedWidth]="true" [icon]="faRectangleXmark"></fa-icon>-->
    </div>
  </div>
  <ul class="menu-list">
    <li class="is-flex" *ngFor="let property of propertiesToShow">
      <ng-container *ngIf="getProperty(property.path)">
        <b class="has-text-weight-bold pl-2 property-name"
          >{{ property.name }}:</b
        >
        <div class="is-flex is-justify-content-flex-end is-fullwidth">
          <code class="has-text-right"> {{ getProperty(property.path) }}</code>
        </div>
      </ng-container>
    </li>
  </ul>
</div>
