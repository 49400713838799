import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import * as Highcharts from 'highcharts';
import { chart } from './ticket-overview.chart';
import { map } from 'lodash';
import { TicketOverviewService } from '../ticket-overview.service';
import { NgIf } from '@angular/common';
import { HighchartsChartModule } from 'highcharts-angular';
import { TranslateService } from '@fino-ui/translation';

@Component({
  selector: 'app-ticket-overview-chart',
  templateUrl: './ticket-overview-chart.component.html',
  imports: [NgIf, HighchartsChartModule],
})
export class TicketOverviewChartComponent implements OnChanges {
  constructor(
    private translateService: TranslateService,
    private ticketOverviewService: TicketOverviewService,
  ) {}

  Highcharts: typeof Highcharts = Highcharts;
  chartOptions = chart;

  public selectedTicketType: boolean | string = false;
  public blacklistStatus: string[] = [];

  @Input()
  public statistic: any;

  @Output()
  public legendClicked = new EventEmitter();

  ngOnChanges(changes: SimpleChanges): void {
    const { statistic } = changes;
    if (statistic) {
      const series = this.ticketOverviewService.calcChartSeries(this.statistic);
      if (series) {
        //@ts-ignore
        this.chartOptions.series = series;
      }

      //@ts-ignore
      this.chartOptions.plotOptions.series.events.legendItemClick =
        this.legendClickEvent.bind(this);

      const translateService = this.translateService;
      //@ts-ignore
      this.chartOptions.legend.labelFormatter = function () {
        return translateService.instant(`app.ticket.status.${this.name}`);
      };

      //@ts-ignore
      this.chartOptions.tooltip.formatter = function () {
        return `${translateService.instant(
          //@ts-ignore
          `app.ticket.status.${this.point.series.name}`,
        )}: <b>${this.y}</b>`;
      };
    }
  }

  legendClickEvent(event: any) {
    this.blacklistStatus = [];
    const { chart } = event.target;
    if (event.target.name !== this.selectedTicketType) {
      map(chart.series, (i) => {
        if (i.name !== event.target.name) {
          i.hide();
          this.blacklistStatus.push(i.name);
        } else {
          i.show();
        }
      });
      this.selectedTicketType = event.target.name;
    } else {
      map(chart.series, (i) => {
        i.show();
      });
      this.selectedTicketType = false;
    }
    this.legendClicked.next(this.blacklistStatus);
    return false;
  }
}
