import { Routes } from '@angular/router';
import { OrganizationRouteGuard } from './core/auth/guards/organizationRouteGuard';
import { TicketOverviewComponent } from './modules/ticket-overview/ticket-overview.component';
import { ClarificationComponent } from './modules/clarification/clarification.component';
import { CaptureTransactionComponent } from './modules/capture-transaction/capture-transaction.component';
import { CaptureWarrantComponent } from './modules/capture-warrant/capture-warrant.component';
import { IdentifyDocumentComponent } from './modules/identify-document/identify-document.component';
import { HomeComponent } from './modules/home/home.component';
import { SiblingsComponent } from './modules/siblings/siblings.component';

const routeWithParam = (component, url: string, data: any = {}) => {
  return [
    {
      path: url,
      component,
      canActivate: [],
      data,
    },
    {
      path: `${url}/:id`,
      component,
      canActivate: [],
      data,
    },
  ];
};

export const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: 'home',
        component: HomeComponent,
        canActivate: [],
      },
      ...routeWithParam(IdentifyDocumentComponent, 'jobs/identifyDocument'),
      ...routeWithParam(SiblingsComponent, 'jobs/mergeSiblings'),
      ...routeWithParam(ClarificationComponent, 'clarification'),
      ...routeWithParam(CaptureWarrantComponent, 'jobs/captureWarrant'),
      ...routeWithParam(
        CaptureTransactionComponent,
        'jobs/captureTransaction',
        { isQualityAssurance: false },
      ),
      ...routeWithParam(
        CaptureTransactionComponent,
        'jobs/captureTransactionQA',
        { isQualityAssurance: true },
      ),
      {
        path: 'ticketOverview',
        component: TicketOverviewComponent,
        canActivate: [OrganizationRouteGuard],
      },
      {
        path: '',
        redirectTo: 'home',
        pathMatch: 'full',
      },
      {
        path: '**',
        redirectTo: 'home',
      },
    ],
  },
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full',
  },
  {
    path: '**',
    redirectTo: 'home',
  },
];
