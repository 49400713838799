import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { SubmittableForm } from '@fino-ui/forms';
import { Subject } from 'rxjs';
import { map as lodashMap, isEmpty, debounce } from 'lodash';
import { IdentifyDocumentService } from 'src/app/modules/identify-document/identify-document.service';
import { faLock, faClose } from '@fortawesome/free-solid-svg-icons';
import { TypeaheadComponent } from '../typeahead/typeahead.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgIf, NgFor, NgTemplateOutlet, DatePipe } from '@angular/common';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { FinoCommonModule } from '@fino-ui/common';
import { FinancesCommonModule, IbanPipe } from '@fino-ui/finances';

@Component({
    selector: 'app-iban-typeahead',
    templateUrl: './iban-typeahead.component.html',
    imports: [
        FormsModule,
        ReactiveFormsModule,
        TypeaheadComponent,
        FinoCommonModule,
        NgIf,
        FaIconComponent,
        NgFor,
        NgTemplateOutlet,
        DatePipe,
        FinancesCommonModule,
    ]
})
export class IbanTypeaheadComponent implements OnInit {
  constructor(public identifyDocumentService: IdentifyDocumentService) {}

  public faLock = faLock;
  public faClose = faClose;

  public search = '';
  @Input()
  public form: SubmittableForm = new SubmittableForm({});

  @Input()
  public tenant: string = '';

  @Input()
  public name: string = '';

  @Input()
  public ibanResults: any = [];

  @Input()
  openTypeahead: Subject<String> = new Subject();

  @Output() ibanSelected = new EventEmitter<any>();
  ibanSelectedInternal = new EventEmitter<any>();

  @ViewChild(TypeaheadComponent) typeaheadComp: TypeaheadComponent | undefined;

  public loading: boolean = false;

  counterResults: any = [];
  showCounterResults = false;

  ibanPipe = new IbanPipe();

  searchChangedDebounced = debounce(this.searchChanged, 400);

  selectedIndex = 0;

  handleResultSelected($event: any) {
    if (!$event) return;
    this.hideResults();
    this.form.get(this.name)?.setValue($event[this.name]);
    this.ibanSelected.next({
      iban: $event[this.name],
      type: this.name,
      customer: $event.client,
      showCounterResults: !this.showCounterResults,
    });
    this.ibanResults = [];
    this.showCounterResults = false;
  }

  handleKeyboadSelected($event: any) {
    this.handleResultSelected(this.ibanResults[$event]);
  }

  @HostListener('window:keydown', ['$event'])
  keyEvent($event: any) {
    if (!this.showCounterResults) return;
    switch ($event.key) {
      case 'ArrowDown':
        this.selectedIndex++;
        break;
      case 'ArrowUp':
        this.selectedIndex--;
        break;
      case 'Enter':
        this.handleResultSelected(this.counterResults[this.selectedIndex]);
        break;
    }

    if (this.selectedIndex < 0) {
      this.selectedIndex = this.counterResults.length - 1;
    }
    if (this.selectedIndex >= this.counterResults.length) {
      this.selectedIndex = 0;
    }
  }

  get hasValue() {
    return this.form.get(this.name)?.value;
  }

  hideResults() {
    this.typeaheadComp?.hideResults();
  }

  searchChanged() {
    const searchTerm = this.form.get(this.name)?.value;

    if (!searchTerm || searchTerm.length <= 2) return;
    if (searchTerm.match(/^\d/)) {
      this.form.get(this.name)?.setValue('DE' + searchTerm);
      return;
    }
    this.loading = true;
    this.identifyDocumentService
      .search(this.name, this.tenant, searchTerm)
      .subscribe((r) => {
        this.loading = false;
        this.ibanResults = r;
        if (isEmpty(this.ibanResults)) {
          this.ibanResults = [];
        }
      });
  }

  get hasError() {
    return (
      !this.form.get(this.name)?.valid && this.form.get(this.name)?.touched
    );
  }

  ngOnInit(): void {
    this.ibanSelectedInternal.subscribe((event) =>
      this.handleResultSelected(event),
    );

    this.openTypeahead.subscribe((subject: any) => {
      this.identifyDocumentService
        .search(subject.type, this.tenant, subject.iban)
        .subscribe((r) => {
          this.counterResults = r;
          this.showCounterResults = true;
        });
    });
  }
}
