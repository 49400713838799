import { Component } from '@angular/core';
import { NgxModalComponent } from 'ngx-modalview';
import { ButtonModule } from '@fino-ui/button';
import { FinoCommonModule } from '@fino-ui/common';
import { FinoTranslationModule } from '@fino-ui/translation';

@Component({
  selector: 'app-approve-modal',
  templateUrl: './approve-modal.component.html',
  imports: [FinoTranslationModule, ButtonModule, FinoCommonModule],
})
export class ApproveModalComponent extends NgxModalComponent<
  { title: string; description: string },
  boolean
> {
  constructor() {
    super();
  }

  title: string = '';
  description: string = '';

  submit() {
    this.result = true;
    this.close();
  }
}
