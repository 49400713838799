import { FormControl, Validators } from '@angular/forms';
import { ibanValidator, SubmittableForm } from '@fino-ui/forms';
import { germanDateValidator } from 'src/app/core/validators/germanDate.validator';

export const directDebitsForm = new SubmittableForm({
  id: new FormControl('', []),
  creditorId: new FormControl('', Validators.required),
  name: new FormControl('', Validators.required),
  purpose: new FormControl('', [Validators.required]),
  mref: new FormControl('', [Validators.required]),
  preferredContact: new FormControl(null, []),
  ignoreCreditor: new FormControl(null, []),
});

export const receivedPaymentsForm = new SubmittableForm({
  id: new FormControl('', []),
  name: new FormControl('', Validators.required),
  purpose: new FormControl('', [Validators.required]),
});

export const reportForm = new SubmittableForm({
  comment: new FormControl('', []),
  preset: new FormControl(null, []),
});

export const standingOrdersForm = new SubmittableForm({
  id: new FormControl('', []),
  name: new FormControl('', [Validators.required]),
  iban: new FormControl('', [Validators.required, ibanValidator]),
  bic: new FormControl('', [Validators.required]),
  amount: new FormControl('', Validators.required),
  currency: new FormControl(null, [Validators.required]),
  interval: new FormControl(null, [Validators.required]),
  executionDay: new FormControl(null, [Validators.required]),
  firstExecution: new FormControl('', [
    Validators.required,
    germanDateValidator,
  ]),
  nextExecution: new FormControl('', [
    Validators.required,
    germanDateValidator,
  ]),
  lastExecution: new FormControl(null, [germanDateValidator]),
  purpose: new FormControl('', [Validators.required]),
  comment: new FormControl('', []),
});
