import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'creditor',
  standalone: true,
})
export class CreditorPipe implements PipeTransform {
  transform(creditorId: string) {
    if (!creditorId) return null;
    return (
      creditorId.slice(0, 2) +
      ' ' +
      creditorId.slice(2, 4) +
      ' ' +
      creditorId.slice(4, 7) +
      ' ' +
      creditorId.slice(7, 11) +
      ' ' +
      creditorId.slice(11, 15) +
      ' ' +
      creditorId.slice(15)
    );
  }
}
