"use strict";

var __extends = this && this.__extends || function () {
  var extendStatics = function (d, b) {
    extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p];
    };
    return extendStatics(d, b);
  };
  return function (d, b) {
    if (typeof b !== "function" && b !== null) throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
    extendStatics(d, b);
    function __() {
      this.constructor = d;
    }
    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.RequiredPartTypeMissing = exports.InvalidCheckDigitException = exports.UnsupportedCountryException = exports.FormatException = exports.FormatViolation = void 0;
var FormatViolation;
(function (FormatViolation) {
  FormatViolation[FormatViolation["UNKNOWN"] = 0] = "UNKNOWN";
  FormatViolation[FormatViolation["NOT_NULL"] = 1] = "NOT_NULL";
  FormatViolation[FormatViolation["NOT_EMPTY"] = 2] = "NOT_EMPTY";
  FormatViolation[FormatViolation["BIC_LENGTH_8_OR_11"] = 3] = "BIC_LENGTH_8_OR_11";
  FormatViolation[FormatViolation["BIC_ONLY_UPPER_CASE_LETTERS"] = 4] = "BIC_ONLY_UPPER_CASE_LETTERS";
  FormatViolation[FormatViolation["BRANCH_CODE_ONLY_LETTERS_OR_DIGITS"] = 5] = "BRANCH_CODE_ONLY_LETTERS_OR_DIGITS";
  FormatViolation[FormatViolation["LOCATION_CODE_ONLY_LETTERS_OR_DIGITS"] = 6] = "LOCATION_CODE_ONLY_LETTERS_OR_DIGITS";
  FormatViolation[FormatViolation["BANK_CODE_ONLY_LETTERS"] = 7] = "BANK_CODE_ONLY_LETTERS";
  FormatViolation[FormatViolation["COUNTRY_CODE_TWO_LETTERS"] = 8] = "COUNTRY_CODE_TWO_LETTERS";
  FormatViolation[FormatViolation["COUNTRY_CODE_ONLY_UPPER_CASE_LETTERS"] = 9] = "COUNTRY_CODE_ONLY_UPPER_CASE_LETTERS";
  FormatViolation[FormatViolation["COUNTRY_CODE_EXISTS"] = 10] = "COUNTRY_CODE_EXISTS";
  FormatViolation[FormatViolation["NATIONAL_CHECK_DIGIT"] = 11] = "NATIONAL_CHECK_DIGIT";
  FormatViolation[FormatViolation["CHECK_DIGIT_TWO_DIGITS"] = 12] = "CHECK_DIGIT_TWO_DIGITS";
  FormatViolation[FormatViolation["CHECK_DIGIT_ONLY_DIGITS"] = 13] = "CHECK_DIGIT_ONLY_DIGITS";
  FormatViolation[FormatViolation["BBAN_LENGTH"] = 14] = "BBAN_LENGTH";
  FormatViolation[FormatViolation["BBAN_ONLY_UPPER_CASE_LETTERS"] = 15] = "BBAN_ONLY_UPPER_CASE_LETTERS";
  FormatViolation[FormatViolation["BBAN_ONLY_DIGITS_OR_LETTERS"] = 16] = "BBAN_ONLY_DIGITS_OR_LETTERS";
  FormatViolation[FormatViolation["BBAN_ONLY_DIGITS"] = 17] = "BBAN_ONLY_DIGITS";
  FormatViolation[FormatViolation["IBAN_VALID_CHARACTERS"] = 18] = "IBAN_VALID_CHARACTERS";
  FormatViolation[FormatViolation["COUNTRY_CODE_NOT_NULL"] = 19] = "COUNTRY_CODE_NOT_NULL";
  FormatViolation[FormatViolation["BANK_CODE_NOT_NULL"] = 20] = "BANK_CODE_NOT_NULL";
  FormatViolation[FormatViolation["ACCOUNT_NUMBER_NOT_NULL"] = 21] = "ACCOUNT_NUMBER_NOT_NULL";
})(FormatViolation = exports.FormatViolation || (exports.FormatViolation = {}));
var FormatException = function (_super) {
  __extends(FormatException, _super);
  function FormatException(formatViolation, msg, expected, actual) {
    var _this = _super.call(this, msg) || this;
    _this.formatViolation = formatViolation;
    _this.expected = expected;
    _this.actual = actual;
    return _this;
  }
  return FormatException;
}(Error);
exports.FormatException = FormatException;
var UnsupportedCountryException = function (_super) {
  __extends(UnsupportedCountryException, _super);
  function UnsupportedCountryException(msg, actual) {
    var _this = _super.call(this, msg) || this;
    _this.actual = actual;
    return _this;
  }
  return UnsupportedCountryException;
}(Error);
exports.UnsupportedCountryException = UnsupportedCountryException;
var InvalidCheckDigitException = function (_super) {
  __extends(InvalidCheckDigitException, _super);
  function InvalidCheckDigitException(msg, expected, actual) {
    var _this = _super.call(this, msg) || this;
    _this.expected = expected;
    _this.actual = actual;
    return _this;
  }
  return InvalidCheckDigitException;
}(Error);
exports.InvalidCheckDigitException = InvalidCheckDigitException;
var RequiredPartTypeMissing = function (_super) {
  __extends(RequiredPartTypeMissing, _super);
  function RequiredPartTypeMissing() {
    return _super !== null && _super.apply(this, arguments) || this;
  }
  return RequiredPartTypeMissing;
}(Error);
exports.RequiredPartTypeMissing = RequiredPartTypeMissing;
