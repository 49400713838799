<div class="mb-3 is-relative" #typeahead>
  <div [formGroup]="form">
    <div class="has-float-label is-flex">
      <input
        class="input"
        [class.is-danger]="hasDanger"
        [class.has-value]="hasValue"
        (keydown)="onKeyDown($event)"
        [formControlName]="formControlName"
        (input)="typing()"
        [mask]="mask"
        (focus)="onFocus()"
        (blur)="onBlur()"
      />
      <div
        class="flex pt-3 is-justify-content-flex-end typeahead-loading-spinner-container"
      >
        <fa-icon
          *ngIf="loading"
          [icon]="faSpinner"
          [spin]="true"
          class="has-text-primary loading-spinner typeahead-loading-spinner"
        ></fa-icon>
        <fa-icon
          *ngIf="!loading && hasValue"
          [icon]="faCopy"
          class="has-text-primary loading-spinner has-click typeahead-loading-spinner"
          (click)="copyToClipboard()"
        ></fa-icon>
      </div>

      <label class="label" [translate]="formControlName"></label>
    </div>
    <div *ngIf="hasDanger" class="has-text-left">
      <div class="help is-danger">{{ error | translate }}</div>
    </div>
  </div>
  <div
    *ngIf="results?.length > 0 && showResults"
    class="box typeahead-results-container"
  >
    <div class="is-flex is-justify-content-flex-end pr-2 pt-1 typeahead-header">
      <fa-icon
        (click)="hideResults()"
        [icon]="faClose"
        class="has-text-grey has-click"
      ></fa-icon>
    </div>
    <div
      *ngFor="let result of results; let i = index"
      class="typeahead-result mb-2"
      [class.has-background-grey-lighter]="
        i === selectedIndex && canNavigateByKeys
      "
    >
      <ng-container
        *ngTemplateOutlet="resultTemplate; context: { $implicit: result }"
      >
      </ng-container>
    </div>
  </div>
  <div
    *ngIf="
      results?.length == 0 && showResults && !loading && hasValue && hasFocus
    "
    class="box typeahead-results-container"
  >
    <div class="is-flex is-justify-content-flex-end pr-2 pt-1 typeahead-header">
      <fa-icon
        (click)="hideResults()"
        [icon]="faClose"
        class="has-text-grey has-click"
      ></fa-icon>
    </div>
    <div
      class="result pb-3 pt-3 is-flex is-justify-content-center has-text-weight-bold"
      translate="typeahead.noResults"
    ></div>
  </div>
</div>
