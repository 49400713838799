import { Component, EventEmitter, Input, Output } from '@angular/core';
import { faBell } from '@fortawesome/free-solid-svg-icons';
import { TICKET_TYPE_COLOR } from '../ticket-overview.constants';
import { TicketOverviewService } from '../ticket-overview.service';
import { SortIconComponent } from '../../../components/sort-icon/sort-icon.component';
import { NgIf, NgFor, DatePipe } from '@angular/common';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { FinoCommonModule } from '@fino-ui/common';
import { FinoTranslationModule } from '@fino-ui/translation';

@Component({
  selector: 'app-ticket-table',
  templateUrl: './ticket-table.component.html',
  styleUrls: ['./ticket-table.component.sass'],
  imports: [
    FinoTranslationModule,
    SortIconComponent,
    NgIf,
    NgFor,
    FinoCommonModule,
    FaIconComponent,
    DatePipe,
  ],
})
export class TicketTableComponent {
  constructor(private ticketOverviewService: TicketOverviewService) {}

  faBell = faBell;

  @Input()
  public isLoading: boolean = false;

  @Input()
  public sortOrder: string | undefined = '';

  @Input()
  public filterProperty: string | undefined = '';

  @Input()
  public organization: string = '';

  @Output()
  public sortByFieldEvent = new EventEmitter();

  @Input()
  public tickets: any;

  getTicketColor(type: string) {
    //@ts-ignore
    return TICKET_TYPE_COLOR[type];
  }

  openTicket(ticket: any) {
    if (ticket.lockedBy) return;
    this.ticketOverviewService
      .getTicketLink(ticket.tenant, ticket.id, this.organization)
      .subscribe((res: any) => this.openKWH(res, ticket.id));
  }

  openKWH(response: any, id = '') {
    this.ticketOverviewService.openKWH(response, id);
  }

  public sortByField($event: any) {
    this.sortByFieldEvent.next($event);
  }
}
