import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Observable, finalize } from 'rxjs';
import { AppService } from 'src/app/app.service';

@Injectable()
export class LoadingHttpInterceptor implements HttpInterceptor {
  constructor(private appService: AppService) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    this.setLoading(req, true);

    return next.handle(req).pipe(
      finalize(() => {
        this.setLoading(req, false);
      }),
    );
  }

  private setLoading(req, loading): void {
    const isCountRequest = req.url.includes('/count');
    const isApiRequest = req.url.includes('/api');
    const isSearchRequest =
      req.url.includes('/search') || req.url.includes('/creditors');

    if (isApiRequest && !isCountRequest && !isSearchRequest) {
      this.appService.loading.set(loading);
    }
  }
}
