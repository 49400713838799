<div class="search-results" [formGroup]="form">
  <app-typeahead
    [formControlName]="name"
    [form]="form"
    (searchChanged)="searchChangedDebounced()"
    [resultSelected]="ibanSelectedInternal"
    [loading]="loading"
    [results]="ibanResults"
    mask="AA00 0000 0000 0000 0000 00"
    [copyWithoutWhitespace]="true"
    [canNavigateByKeys]="true"
    (keyboardSelected)="handleKeyboadSelected($event)"
    [resultTemplate]="itemTpl"
    ngDefaultControl
  ></app-typeahead>

  <ng-template #itemTpl let-item>
    <div
      [class.has-click]="!item.closed"
      class="is-flex is-justify-content-center is-align-items-center"
      (click)="handleResultSelected(item)"
    >
      <fa-icon
        *ngIf="item.closed"
        size="lg"
        [icon]="faLock"
        class="has-text-danger mr-4"
      ></fa-icon>
      <div class="is-flex is-flex-direction-column">
        <strong [class.has-text-grey-light]="item.closed"
          >{{ item[name] | iban }}
          <span *ngIf="item.client">({{ item.client }})</span></strong
        >
        <small
          class="has-text-centered"
          [class.has-text-grey-light]="item.closed"
          >{{ item.received | date : "dd.MM.yy, HH:mm" }}</small
        >
      </div>
    </div>
  </ng-template>

  <section *ngIf="showCounterResults" class="box typeahead-results-container">
    <div class="is-flex is-justify-content-flex-end pr-2 pt-1 typeahead-header">
      <fa-icon
        (click)="hideResults()"
        [icon]="faClose"
        class="has-text-grey has-click"
      ></fa-icon>
    </div>
    <div
      class="typeahead-result"
      *ngFor="let counterResult of counterResults; let i = index"
      [class.has-background-grey-lighter]="i === selectedIndex"
    >
      <ng-container
        *ngTemplateOutlet="itemTpl; context: { $implicit: counterResult }"
      >
      </ng-container>
    </div>
  </section>
</div>
