import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { filter } from 'lodash';
import { Routes } from 'src/app/core/routes/routes';
import { IdentifyDocumentService } from '../identify-document.service';
import { NgIf } from '@angular/common';
import { ButtonModule } from '@fino-ui/button';
import { FinoCommonModule } from '@fino-ui/common';
import { FinoTranslationModule } from '@fino-ui/translation';

@Component({
  selector: 'app-siblings',
  templateUrl: './siblings.component.html',
  imports: [NgIf, ButtonModule, FinoTranslationModule, FinoCommonModule],
})
export class SiblingsComponent implements OnChanges {
  @Input()
  jobId: string = '';

  numSiblings: number = 0;
  numSiblingsOpen: number = 0;

  constructor(
    private router: Router,
    private identifyDocumentService: IdentifyDocumentService,
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['jobId']?.currentValue) {
      this.countSiblings();
    }
  }

  mergeSiblings() {
    if (!this.jobId) {
      alert(`Job ID is not set: ${this.jobId}`);
    }

    this.router.navigate([Routes.MergeSiblings, this.jobId]);
  }

  countSiblings() {
    this.identifyDocumentService
      .getSiblings(this.jobId)
      .subscribe((siblings: any) => {
        this.numSiblings = (siblings as []).length;
        this.numSiblingsOpen = filter(
          siblings as [],
          (s: any) => s.status === 'open',
        ).length;
      });
  }
}
