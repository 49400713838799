import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { SubmittableForm } from '@fino-ui/forms';
import { Output, EventEmitter } from '@angular/core';
import { clone, cloneDeep, filter, map, merge, pick } from 'lodash';
import {
  faEdit,
  faTrash,
  faCopy,
  faTimes,
} from '@fortawesome/free-solid-svg-icons';
import { updateOrAdd } from 'src/app/core/helpers/helpers';
import { CaptureTransactionService } from 'src/app/modules/capture-transaction/capture-transaction.service';
import { debounce, interval } from 'rxjs';
import {
  DIRECT_DEBITS_FIELDS,
  isValidCreditorId,
} from './direct-debits.contants';

import { v4 as uuidv4 } from 'uuid';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Creditor } from 'src/app/core/models/creditor';
import { TypeaheadComponent } from '../typeahead/typeahead.component';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { CreditorPipe } from '../../core/pipes/creditor.pipe';
import { InputModule } from '@fino-ui/input';
import { ButtonModule } from '@fino-ui/button';
import { FinoCommonModule } from '@fino-ui/common';
import { FinoTranslationModule } from '@fino-ui/translation';

@Component({
  selector: 'app-direct-debits',
  templateUrl: './direct-debits.component.html',
  styleUrls: ['./direct-debits.component.sass'],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    TypeaheadComponent,
    FinoTranslationModule,
    FinoCommonModule,
    FaIconComponent,
    InputModule,
    ButtonModule,
    CreditorPipe,
  ],
})
export class DirectDebitsComponent implements OnInit, OnChanges {
  @Input()
  public form: SubmittableForm = new SubmittableForm({});

  @Input()
  public directDebits: any[] = [];
  public filteredDirectDebits: any[] = [];

  public searchForm: SubmittableForm = new SubmittableForm({
    search: new FormControl('', []),
  });

  @Output() directDebitsSubmitted = new EventEmitter<any>();

  directDebitSelected = new EventEmitter<boolean>();

  public isUpdateMode: boolean = false;

  public creditorNameResults: any[] = [];
  public creditorIdResults: any[] = [];

  public creditorNameLoading = false;
  public creditorIdLoading = false;
  public model: any = {};

  public toggleAllDelete = true;
  public directDebitsFields = DIRECT_DEBITS_FIELDS;
  public creditorIdMayInvalid = false;
  public isMaybeOneTimeDirectDebit = false;

  faEdit = faEdit;
  faTrash = faTrash;
  faCopy = faCopy;
  faTimes = faTimes;

  constructor(public captureTransactionService: CaptureTransactionService) {}

  ngOnInit(): void {
    this.setupFilterForm();
    this.setupCreditorValidation();
    this.setupForm();
  }

  submit() {
    this.form.markAllAsTouched();
    if (this.form.valid) {
      this.form.controls['creditorId'].setValue(
        this.form.controls['creditorId'].value.replace(/ /g, ''),
      );

      const toBeAdded = this.form.value;
      if (!toBeAdded.id) {
        toBeAdded.id = uuidv4();
      }
      this.isUpdateMode = false;
      updateOrAdd(
        this.directDebits,
        merge(toBeAdded, { selected: true }),
        'id',
      );

      this.filteredDirectDebits = clone(this.directDebits);
      this.searchForm.reset();

      this.directDebitsSubmitted.next(this.form.value);
      this.form.reset();
    }
  }

  get hasName() {
    return this.form.controls['name']?.value;
  }

  get hasCreditorId() {
    return this.form.controls['creditorId']?.value;
  }

  get hasDirectDebits() {
    return this.directDebits && this.directDebits.length > 0;
  }

  get isFormValid() {
    return this.form.valid;
  }

  setupForm() {
    this.form.controls['purpose']?.valueChanges.subscribe((purpose: string) => {
      this.isMaybeOneTimeDirectDebit = purpose?.toUpperCase().includes('ELV');
    });
  }

  deleteItem(item: any, selected: boolean = true) {
    item.selected = selected;
    this.directDebitsSubmitted.next(this.form.value);
  }

  deleteAllItems() {
    this.toggleAllDelete = !this.toggleAllDelete;
    map(this.directDebits, (d) => this.deleteItem(d, this.toggleAllDelete));
  }

  duplicateItem(item: any) {
    const cloned = cloneDeep(item);
    cloned.id = uuidv4();
    this.form.patchValue(cloned);
  }

  editItem(item: any) {
    this.isUpdateMode = true;
    this.form.patchValue(pick(item, this.directDebitsFields));
  }

  handleCreditorResultSelected(creditor: Creditor) {
    if (!creditor) return;

    this.form.patchValue({
      creditorId: creditor.creditorId,
      name: creditor.name,
      preferredContact: creditor.preferredContact,
      ignoreCreditor: creditor.ignoreCreditor,
    });

    if (this.isIgnorableCreditor(creditor)) {
      this.form.patchValue({ purpose: '-', mref: '-' });
    } else {
      this.form.patchValue({ purpose: '', mref: '' });
    }
    this.directDebitSelected.next(true);
  }

  isIgnorableCreditor(creditor: Creditor) {
    return creditor.ignoreCreditor || creditor.preferredContact === 'online';
  }

  close() {
    this.directDebitSelected.next(true);
  }

  setupCreditorValidation() {
    this.form.controls['creditorId'].valueChanges.subscribe((v) => {
      v = v?.replace(/ /g, '');
      this.creditorIdMayInvalid = !this.form.untouched && !isValidCreditorId(v);
    });
  }

  nameSearchChanged() {
    const searchTerm = this.form.get('name')?.value;
    this.creditorNameLoading = true;
    if (!searchTerm) return;

    this.captureTransactionService
      .getCreditors(searchTerm, 'q')
      .pipe(debounce(() => interval(1000)))
      .subscribe((r) => {
        this.creditorNameLoading = false;
        this.creditorNameResults = r as [];
      });
  }

  keyboardSelectedCreditor($event: any) {
    this.handleCreditorResultSelected(this.creditorIdResults[$event]);
  }

  keyboardSelectedName($event: any) {
    this.handleCreditorResultSelected(this.creditorNameResults[$event]);
  }

  ngOnChanges(changes: SimpleChanges): void {
    const { directDebits } = changes;
    if (directDebits) {
      this.filteredDirectDebits = clone(this.directDebits);
    }
  }

  setupFilterForm() {
    this.searchForm.controls['search'].valueChanges.subscribe(
      (keyword: string) => {
        if (!keyword) {
          this.filteredDirectDebits = clone(this.directDebits);
          return;
        }
        this.filteredDirectDebits = filter(this.directDebits, (item) =>
          item.name.toLowerCase().includes(keyword.toLowerCase()),
        );
      },
    );
  }

  creditorIdSearchChanged() {
    const searchTerm = this.form.get('creditorId')?.value;
    this.creditorIdLoading = true;
    if (!searchTerm) return;

    this.captureTransactionService
      .getCreditors(searchTerm, 'cid')
      .pipe(debounce(() => interval(1000)))
      .subscribe((r) => {
        this.creditorIdLoading = false;
        this.creditorIdResults = r as [];
      });
  }
}
