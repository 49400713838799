export const PROPERTIES = [
  {
    name: 'Job ID',
    path: 'id',
  },
  {
    name: 'Retarus ID',
    path: 'envelope.jobId',
  },
  {
    name: 'Previous Job ID',
    path: ['envelope.previousJobId', 'previousJobId'],
  },
  {
    name: 'StatusChangedBy',
    path: 'statusChangedBy',
  },
  {
    name: 'RecievedByUpload',
    path: 'receivedByUpload',
  },
  {
    name: 'StatusChangedAt',
    path: 'statusChangedAt',
  },
  {
    name: 'status',
    path: 'status',
  },
  {
    name: 'New Bank',
    path: 'newBank',
  },
  {
    name: 'Old Iban',
    path: 'oldIban',
  },
  {
    name: 'New Iban',
    path: 'newIban',
  },
  {
    name: 'Old Bank',
    path: 'oldBank',
  },
  {
    name: 'recieved',
    path: 'envelope.received',
  },
  {
    name: 'recipient',
    path: 'envelope.recipient',
  },
  {
    name: 'sender',
    path: 'envelope.sender',
  },
  {
    name: 'senderName',
    path: 'envelope.senderName',
  },
  {
    name: 'tenant',
    path: ['envelope.tenant', 'tenant'],
  },
  {
    name: 'tenantName',
    path: 'bankName',
  },
  {
    name: 'kwhEmail',
    path: 'kwhEmail',
  },
  {
    name: 'tenantAddressName',
    path: 'contact.name',
  },
  {
    name: 'tenantAddress',
    path: 'contact.address',
  },
  {
    name: 'tenantPostCode',
    path: 'contact.postcode',
  },
  {
    name: 'tenantCity',
    path: 'contact.city',
  },
];
