"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.BIC = void 0;
var country_1 = require("./country");
var bicUtil = require("./bicUtil");
var BIC = function () {
  function BIC(bic) {
    bicUtil.validate(bic);
    this.value = bic;
  }
  BIC.prototype.getBankCode = function () {
    return bicUtil.getBankCode(this.value);
  };
  BIC.prototype.getCountryCode = function () {
    return country_1.countryByCode(bicUtil.getCountryCode(this.value));
  };
  BIC.prototype.getLocationCode = function () {
    return bicUtil.getLocationCode(this.value);
  };
  BIC.prototype.getBranchCode = function () {
    if (bicUtil.hasBranchCode(this.value)) {
      return bicUtil.getBranchCode(this.value);
    }
    return null;
  };
  BIC.prototype.toString = function () {
    return this.value;
  };
  BIC.isValid = function (bic) {
    try {
      bicUtil.validate(bic);
    } catch (_a) {
      return false;
    }
    return true;
  };
  return BIC;
}();
exports.BIC = BIC;
