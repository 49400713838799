import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { isEmpty, map, omitBy } from 'lodash';
import { Api } from 'src/app/core/api/Api';
import { TICKET_TYPE_COLOR } from './ticket-overview.constants';

@Injectable({
  providedIn: 'root',
})
export class TicketOverviewService {
  constructor(private http: HttpClient) {}

  fetchTickets(
    organization: string,
    term: string,
    sortField: string,
    sortOrder: string,
    page: number,
    blacklistStatus: string[],
    hasRemindersPaused: boolean,
    hasOpenStandingOrders: boolean,
  ) {
    return this.http.get(Api.TicketOverview.Overview(organization), {
      params: omitBy(
        {
          textQuery: term,
          sortField,
          sortOrder,
          page,
          blacklistStatus,
          hasRemindersPaused,
          hasOpenStandingOrders,
        },
        (v) => !v,
      ),
    });
  }

  getTicketLink(tenant: string, id: string, organization: string) {
    return this.http.get(Api.TicketOverview.Ticket(organization), {
      params: { tenant, id },
    });
  }

  getTenantLink(tenant: string, organization: string) {
    return this.http.get(Api.TicketOverview.Tenant(organization), {
      params: { tenant },
    });
  }

  fetchTenants(organization: string) {
    return this.http.get(Api.TicketOverview.Tenants(organization));
  }

  calcChartSeries(data: any) {
    if (isEmpty(data)) return null;
    return map(data, (value, key) => {
      return {
        name: key,
        //@ts-ignore
        color: TICKET_TYPE_COLOR[key] || '#32323A',
        visible: true,
        data: [[value]],
      };
    });
  }

  openKWH(response: any, id = '') {
    const maybeTicketId = id ? `&ticketId=${id}` : '';
    let url = `${response.baseUrl}loginByToken?token=${response.token}${maybeTicketId}`;
    if (!url.startsWith('http')) {
      url = 'https://' + url;
    }
    window.open(url, '_blank');
  }
}
