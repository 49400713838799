"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.generateIban = exports.validateCheckDigit = exports.toFormattedStringBBAN = exports.toFormattedString = exports.replaceCheckDigit = exports.getIdentificationNumber = exports.getOwnerAccountType = exports.getAccountType = exports.getCurrencyType = exports.getNationalCheckDigit = exports.getBranchCode = exports.getBankCode = exports.getAccountNumber = exports.getBban = exports.getCountryCodeAndCheckDigit = exports.getCountryCode = exports.getCheckDigit = exports.getIbanLength = exports.isSupportedCountry = exports.validateBban = exports.validate = exports.calculateCheckDigit = exports.DEFAULT_CHECK_DIGIT = void 0;
var country_1 = require("./country");
var bbanStructure_1 = require("./bbanStructure");
var structurePart_1 = require("./structurePart");
var ibanBuilder_1 = require("./ibanBuilder");
var exceptions_1 = require("./exceptions");
var ucRegex = /^[A-Z]+$/;
var numRegex = /^[0-9]+$/;
exports.DEFAULT_CHECK_DIGIT = "00";
var MOD = 97;
var MAX = 999999999;
var COUNTRY_CODE_INDEX = 0;
var COUNTRY_CODE_LENGTH = 2;
var CHECK_DIGIT_INDEX = COUNTRY_CODE_LENGTH;
var CHECK_DIGIT_LENGTH = 2;
var BBAN_INDEX = CHECK_DIGIT_INDEX + CHECK_DIGIT_LENGTH;
function calculateCheckDigit(iban) {
  var reformattedIban = replaceCheckDigit(iban, exports.DEFAULT_CHECK_DIGIT);
  var modResult = calculateMod(reformattedIban);
  var checkDigit = String(98 - modResult);
  return checkDigit.padStart(2, "0");
}
exports.calculateCheckDigit = calculateCheckDigit;
function validate(iban) {
  validateNotEmpty(iban);
  validateCountryCode(iban);
  validateCheckDigitPresence(iban);
  validateBban(getCountryCode(iban), getBban(iban));
  validateCheckDigit(iban);
}
exports.validate = validate;
function validateBban(countryCode, bban) {
  validateCountryCode(countryCode);
  var structure = getBbanStructure(countryCode);
  if (!structure) {
    throw new Error("Internal error, expected structure");
  }
  structure.validate(bban);
}
exports.validateBban = validateBban;
function isSupportedCountry(countryCode) {
  return bbanStructure_1.BbanStructure.forCountry(countryCode) != null;
}
exports.isSupportedCountry = isSupportedCountry;
function getIbanLength(countryCode) {
  var structure = getBbanStructure(countryCode);
  if (structure === null) {
    throw new exceptions_1.UnsupportedCountryException("Unsuppored country", countryCode);
  }
  return COUNTRY_CODE_LENGTH + CHECK_DIGIT_LENGTH + structure.getBbanLength();
}
exports.getIbanLength = getIbanLength;
function getCheckDigit(iban) {
  return iban.substring(CHECK_DIGIT_INDEX, CHECK_DIGIT_INDEX + CHECK_DIGIT_LENGTH);
}
exports.getCheckDigit = getCheckDigit;
function getCountryCode(iban) {
  return iban.substring(COUNTRY_CODE_INDEX, COUNTRY_CODE_INDEX + COUNTRY_CODE_LENGTH);
}
exports.getCountryCode = getCountryCode;
function getCountryCodeAndCheckDigit(iban) {
  return iban.substring(COUNTRY_CODE_INDEX, COUNTRY_CODE_INDEX + COUNTRY_CODE_LENGTH + CHECK_DIGIT_LENGTH);
}
exports.getCountryCodeAndCheckDigit = getCountryCodeAndCheckDigit;
function getBban(iban) {
  return iban.substring(BBAN_INDEX);
}
exports.getBban = getBban;
function getAccountNumber(iban) {
  return extractBbanEntry(iban, structurePart_1.PartType.ACCOUNT_NUMBER);
}
exports.getAccountNumber = getAccountNumber;
function getBankCode(iban) {
  return extractBbanEntry(iban, structurePart_1.PartType.BANK_CODE);
}
exports.getBankCode = getBankCode;
function getBranchCode(iban) {
  return extractBbanEntry(iban, structurePart_1.PartType.BRANCH_CODE);
}
exports.getBranchCode = getBranchCode;
function getNationalCheckDigit(iban) {
  return extractBbanEntry(iban, structurePart_1.PartType.NATIONAL_CHECK_DIGIT);
}
exports.getNationalCheckDigit = getNationalCheckDigit;
function getCurrencyType(iban) {
  return extractBbanEntry(iban, structurePart_1.PartType.CURRENCY_TYPE);
}
exports.getCurrencyType = getCurrencyType;
function getAccountType(iban) {
  return extractBbanEntry(iban, structurePart_1.PartType.ACCOUNT_TYPE);
}
exports.getAccountType = getAccountType;
function getOwnerAccountType(iban) {
  return extractBbanEntry(iban, structurePart_1.PartType.OWNER_ACCOUNT_NUMBER);
}
exports.getOwnerAccountType = getOwnerAccountType;
function getIdentificationNumber(iban) {
  return extractBbanEntry(iban, structurePart_1.PartType.IDENTIFICATION_NUMBER);
}
exports.getIdentificationNumber = getIdentificationNumber;
function replaceCheckDigit(iban, checkDigit) {
  return getCountryCode(iban) + checkDigit + getBban(iban);
}
exports.replaceCheckDigit = replaceCheckDigit;
function toFormattedString(iban, separator) {
  if (separator === void 0) {
    separator = " ";
  }
  return iban.replace(/(.{4})/g, "$1" + separator).trim();
}
exports.toFormattedString = toFormattedString;
function toFormattedStringBBAN(iban, separator) {
  if (separator === void 0) {
    separator = " ";
  }
  var structure = getBbanStructure(iban);
  if (structure === null) {
    throw new Error("should't happen - already validated IBAN");
  }
  var bban = getBban(iban);
  var parts = structure.getParts().reduce(function (acc, part) {
    var value = structure.extractValue(bban, part.getPartType());
    return acc.concat(value || "", part.trailingSeparator ? separator : "");
  }, []);
  parts.pop();
  return parts.join("");
}
exports.toFormattedStringBBAN = toFormattedStringBBAN;
function validateCheckDigit(iban) {
  if (calculateMod(iban) != 1) {
    var checkDigit = getCheckDigit(iban);
    var expectedCheckDigit = calculateCheckDigit(iban);
    throw new exceptions_1.InvalidCheckDigitException("[" + iban + "] has invalid check digit: " + checkDigit + ", expected check digit is: " + expectedCheckDigit, checkDigit, expectedCheckDigit);
  }
}
exports.validateCheckDigit = validateCheckDigit;
function generateIban(country, bank, account) {
  try {
    return new ibanBuilder_1.IBANBuilder().countryCode(country_1.CountryCode[country]).bankCode(bank).accountNumber(account).build().toString();
  } catch (e) {
    return false;
  }
}
exports.generateIban = generateIban;
function validateNotEmpty(iban) {
  if (iban == null) {
    throw new exceptions_1.FormatException(exceptions_1.FormatViolation.NOT_NULL, "Null can't be a valid Iban.");
  }
  if (iban.length === 0) {
    throw new exceptions_1.FormatException(exceptions_1.FormatViolation.NOT_EMPTY, "Empty string can't be a valid Iban.");
  }
}
function validateCountryCode(iban) {
  if (iban.length < COUNTRY_CODE_LENGTH) {
    throw new exceptions_1.FormatException(exceptions_1.FormatViolation.COUNTRY_CODE_TWO_LETTERS, "Iban must contain 2 char country code.", iban);
  }
  var countryCode = getCountryCode(iban);
  if (countryCode !== countryCode.toUpperCase() || !ucRegex.test(countryCode)) {
    throw new exceptions_1.FormatException(exceptions_1.FormatViolation.COUNTRY_CODE_ONLY_UPPER_CASE_LETTERS, "Iban country code must contain upper case letters.", countryCode);
  }
  var country = country_1.countryByCode(countryCode);
  if (country == null) {
    throw new exceptions_1.FormatException(exceptions_1.FormatViolation.COUNTRY_CODE_EXISTS, "Iban contains non existing country code.", countryCode);
  }
  var structure = bbanStructure_1.BbanStructure.forCountry(country);
  if (structure == null) {
    throw new exceptions_1.UnsupportedCountryException("Country code is not supported.", countryCode);
  }
}
function validateCheckDigitPresence(iban) {
  if (iban.length < COUNTRY_CODE_LENGTH + CHECK_DIGIT_LENGTH) {
    throw new exceptions_1.FormatException(exceptions_1.FormatViolation.CHECK_DIGIT_TWO_DIGITS, "Iban must contain 2 digit check digit.", iban.substring(COUNTRY_CODE_LENGTH));
  }
  var checkDigit = getCheckDigit(iban);
  if (!numRegex.test(checkDigit)) {
    throw new exceptions_1.FormatException(exceptions_1.FormatViolation.CHECK_DIGIT_ONLY_DIGITS, "Iban's check digit should contain only digits.", checkDigit);
  }
}
function calculateMod(iban) {
  var reformattedIban = getBban(iban) + getCountryCodeAndCheckDigit(iban);
  var VA = "A".charCodeAt(0);
  var VZ = "Z".charCodeAt(0);
  var V0 = "0".charCodeAt(0);
  var V9 = "9".charCodeAt(0);
  function addSum(total, value) {
    var newTotal = (value > 9 ? total * 100 : total * 10) + value;
    return newTotal > MAX ? newTotal % MOD : newTotal;
  }
  var total = reformattedIban.toUpperCase().split("").reduce(function (total, ch) {
    var code = ch.charCodeAt(0);
    if (VA <= code && code <= VZ) {
      return addSum(total, code - VA + 10);
    } else if (V0 <= code && code <= V9) {
      return addSum(total, code - V0);
    } else {
      throw new exceptions_1.FormatException(exceptions_1.FormatViolation.IBAN_VALID_CHARACTERS, "Invalid Character[" + ch + "] = '" + code + "'", ch);
    }
  }, 0);
  return total % MOD;
}
function getBbanStructure(iban) {
  var countryCode = country_1.countryByCode(getCountryCode(iban));
  if (!countryCode) {
    return null;
  }
  return getBbanStructureByCountry(countryCode);
}
function getBbanStructureByCountry(countryCode) {
  return bbanStructure_1.BbanStructure.forCountry(countryCode);
}
function extractBbanEntry(iban, partType) {
  var bban = getBban(iban);
  var structure = getBbanStructure(iban);
  if (structure === null) {
    return null;
  }
  return structure.extractValue(bban, partType);
}
