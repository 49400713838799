"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.randInt = void 0;
function randInt(maxVal, minVal) {
  if (minVal === void 0) {
    minVal = 0;
  }
  return Math.floor(Math.random() * maxVal) + minVal;
}
exports.randInt = randInt;
