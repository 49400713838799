"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.IBANBuilder = void 0;
var ibanUtil = require("./ibanUtil");
var bbanStructure_1 = require("./bbanStructure");
var structurePart_1 = require("./structurePart");
var randInt_1 = require("./randInt");
var exceptions_1 = require("./exceptions");
var iban_1 = require("./iban");
var IBANBuilder = function () {
  function IBANBuilder() {}
  IBANBuilder.prototype.countryCode = function (countryCode) {
    this.countryCodeValue = countryCode;
    return this;
  };
  IBANBuilder.prototype.bankCode = function (bankCode) {
    this.bankCodeValue = bankCode;
    return this;
  };
  IBANBuilder.prototype.branchCode = function (branchCode) {
    this.branchCodeValue = branchCode;
    return this;
  };
  IBANBuilder.prototype.accountNumber = function (accountNumber) {
    this.accountNumberValue = accountNumber;
    return this;
  };
  IBANBuilder.prototype.nationalCheckDigit = function (nationalCheckDigit) {
    this.nationalCheckDigitValue = nationalCheckDigit;
    return this;
  };
  IBANBuilder.prototype.accountType = function (accountType) {
    this.accountTypeValue = accountType;
    return this;
  };
  IBANBuilder.prototype.ownerAccountType = function (ownerAccountType) {
    this.ownerAccountTypeValue = ownerAccountType;
    return this;
  };
  IBANBuilder.prototype.identificationNumber = function (identificationNumber) {
    this.identificationNumberValue = identificationNumber;
    return this;
  };
  IBANBuilder.prototype.build = function (fillRandom, validate) {
    if (fillRandom === void 0) {
      fillRandom = true;
    }
    if (validate === void 0) {
      validate = true;
    }
    if (fillRandom && this.countryCodeValue == null) {
      var countryCodes = bbanStructure_1.BbanStructure.supportedCountries();
      this.countryCodeValue = countryCodes[randInt_1.randInt(countryCodes.length)];
    }
    var structure = bbanStructure_1.BbanStructure.forCountry(this.countryCodeValue);
    if (structure === null) {
      throw new Error("shouldn't happen");
    }
    this.fillMissingFieldsRandomly(fillRandom);
    var formattedIban = this.formatIban();
    var checkDigit = ibanUtil.calculateCheckDigit(formattedIban);
    var ibanValue = ibanUtil.replaceCheckDigit(formattedIban, checkDigit);
    if (validate) {
      ibanUtil.validate(ibanValue);
    }
    return new iban_1.IBAN(ibanValue);
  };
  IBANBuilder.prototype.formatBban = function () {
    var parts = [];
    var structure = bbanStructure_1.BbanStructure.forCountry(this.countryCodeValue);
    if (structure === null) {
      throw new exceptions_1.UnsupportedCountryException("Country code is not supported.", this.countryCodeValue);
    }
    for (var _i = 0, _a = structure.getParts(); _i < _a.length; _i++) {
      var part = _a[_i];
      switch (part.getPartType()) {
        case structurePart_1.PartType.BANK_CODE:
          parts.push(this.bankCodeValue);
          break;
        case structurePart_1.PartType.BRANCH_CODE:
          parts.push(this.branchCodeValue);
          break;
        case structurePart_1.PartType.ACCOUNT_NUMBER:
          parts.push(this.accountNumberValue);
          break;
        case structurePart_1.PartType.NATIONAL_CHECK_DIGIT:
          parts.push(this.nationalCheckDigitValue);
          break;
        case structurePart_1.PartType.ACCOUNT_TYPE:
          parts.push(this.accountTypeValue);
          break;
        case structurePart_1.PartType.OWNER_ACCOUNT_NUMBER:
          parts.push(this.ownerAccountTypeValue);
          break;
        case structurePart_1.PartType.IDENTIFICATION_NUMBER:
          parts.push(this.identificationNumberValue);
          break;
      }
    }
    return parts.join("");
  };
  IBANBuilder.prototype.formatIban = function () {
    return "" + this.countryCodeValue + ibanUtil.DEFAULT_CHECK_DIGIT + this.formatBban();
  };
  IBANBuilder.prototype.fillMissingFieldsRandomly = function (fillRandom) {
    var structure = bbanStructure_1.BbanStructure.forCountry(this.countryCodeValue);
    if (structure == null) {
      throw new exceptions_1.UnsupportedCountryException("Country code is not supported.", this.countryCodeValue);
    }
    var needCheckDigit = false;
    for (var _i = 0, _a = structure.getParts(); _i < _a.length; _i++) {
      var entry = _a[_i];
      switch (entry.getPartType()) {
        case structurePart_1.PartType.BANK_CODE:
          if (!this.bankCodeValue) {
            this.bankCodeValue = entry.generate("", structure);
          } else if (!fillRandom) {
            throw new exceptions_1.FormatException(exceptions_1.FormatViolation.NOT_NULL, "bankCode is required; it cannot be null");
          }
          break;
        case structurePart_1.PartType.BRANCH_CODE:
          if (!this.branchCodeValue) {
            this.branchCodeValue = entry.generate("", structure);
          } else if (!fillRandom) {
            throw new exceptions_1.FormatException(exceptions_1.FormatViolation.NOT_NULL, "branchCode is required; it cannot be null");
          }
          break;
        case structurePart_1.PartType.ACCOUNT_NUMBER:
          if (!this.accountNumberValue) {
            this.accountNumberValue = entry.generate("", structure);
          } else if (!fillRandom) {
            throw new exceptions_1.FormatException(exceptions_1.FormatViolation.NOT_NULL, "accountNumber is required; it cannot be null");
          }
          break;
        case structurePart_1.PartType.NATIONAL_CHECK_DIGIT:
          if (!this.nationalCheckDigitValue) {
            needCheckDigit = true;
            this.nationalCheckDigitValue = "".padStart(entry.getLength(), "0");
          }
          break;
        case structurePart_1.PartType.ACCOUNT_TYPE:
          if (!this.accountTypeValue) {
            this.accountTypeValue = entry.generate("", structure);
          } else if (!fillRandom) {
            throw new exceptions_1.FormatException(exceptions_1.FormatViolation.NOT_NULL, "accountType is required; it cannot be null");
          }
          break;
        case structurePart_1.PartType.OWNER_ACCOUNT_NUMBER:
          if (!this.ownerAccountTypeValue) {
            this.ownerAccountTypeValue = entry.generate("", structure);
          } else if (!fillRandom) {
            throw new exceptions_1.FormatException(exceptions_1.FormatViolation.NOT_NULL, "ownerAccountType is required; it cannot be null");
          }
          break;
        case structurePart_1.PartType.IDENTIFICATION_NUMBER:
          if (!this.identificationNumberValue) {
            this.identificationNumberValue = entry.generate("", structure);
          } else if (!fillRandom) {
            throw new exceptions_1.FormatException(exceptions_1.FormatViolation.NOT_NULL, "indentificationNumber is required; it cannot be null");
          }
          break;
      }
    }
    if (needCheckDigit) {
      for (var _b = 0, _c = structure.getParts(); _b < _c.length; _b++) {
        var entry = _c[_b];
        if (entry.getPartType() === structurePart_1.PartType.NATIONAL_CHECK_DIGIT) {
          var bban = this.formatBban();
          this.nationalCheckDigitValue = entry.generate(bban, structure);
        }
      }
    }
  };
  return IBANBuilder;
}();
exports.IBANBuilder = IBANBuilder;
