import { NgFor, NgIf } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import {
  Component,
  Input,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {
  FormControl,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { SubmittableForm, ibanValidator } from '@fino-ui/forms';
import { InputModule } from '@fino-ui/input';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import {
  faCopy,
  faEnvelopeOpen,
  faShuffle,
  faSquareEnvelope,
  faTrash,
} from '@fortawesome/free-solid-svg-icons';
import { isEmpty, map, merge } from 'lodash';
import { PdfJsViewerModule } from 'ng2-pdfjs-viewer';
import { ClipboardModule } from 'ngx-clipboard';
import { NgxModalService } from 'ngx-modalview';
import { ToastrService } from 'ngx-toastr';
import { LocalStorage } from 'ngx-webstorage';
import { Subject, Subscription } from 'rxjs';
import { ApproveModalComponent } from 'src/app/components/approve-modal/approve-modal.component';
import { NavbarService } from 'src/app/components/navbar/navbar.service';
import { Routes } from 'src/app/core/routes/routes';
import { FinoCommonModule } from '@fino-ui/common';
import { IbanTypeaheadComponent } from '../../components/iban-typeahead/iban-typeahead.component';
import { TenantInfosComponent } from '../../components/tenant-infos/tenant-infos.component';
import { IbanColorPipe } from '../../core/pipes/iban-color.pipe';
import {
  DOCUMENTTYPES,
  REJECTION_REASONS,
  REJECTION_REASONS_DETAILED,
  REJECTION_REASONS_GENERAL,
} from './identify-document.constants';
import { IdentifyDocumentService } from './identify-document.service';
import { SiblingsComponent } from './siblings/siblings.component';
import { ButtonModule } from '@fino-ui/button';
import { FinancesCommonModule } from '@fino-ui/finances';
import { FinoTranslationModule, TranslateService } from '@fino-ui/translation';

const IBAN_VALIDATORS = [Validators.required, ibanValidator];

@Component({
  selector: 'app-identify-document',
  templateUrl: './identify-document.component.html',
  styleUrls: ['./identify-document.component.sass'],
  imports: [
    NgIf,
    PdfJsViewerModule,
    FormsModule,
    ReactiveFormsModule,
    SiblingsComponent,
    FinoTranslationModule,
    FaIconComponent,
    FinoCommonModule,
    ClipboardModule,
    ButtonModule,
    IbanTypeaheadComponent,
    InputModule,
    TenantInfosComponent,
    IbanColorPipe,
    FinancesCommonModule,
  ],
})
export class IdentifyDocumentComponent implements OnInit {
  constructor(
    private identifyDocumentService: IdentifyDocumentService,
    private route: ActivatedRoute,
    private router: Router,
    private translateService: TranslateService,
    private toastr: ToastrService,
    private navbarService: NavbarService,
    private ngxModalService: NgxModalService,
  ) {}

  public pdfUrl: string = '';
  public job: any;
  public noJobLeft: boolean = false;
  public form: SubmittableForm = new SubmittableForm({
    tenant: new FormControl('', Validators.required),
    documentType: new FormControl('', Validators.required),
    oldIban: new FormControl('', IBAN_VALIDATORS),
    newIban: new FormControl('', IBAN_VALIDATORS),
    rejectionReason: new FormControl('', Validators.required),
    rejectionReasonText: new FormControl(''),
  });
  public loadNextDocumentForm: SubmittableForm = new SubmittableForm({
    loadNext: new FormControl(true),
  });

  @LocalStorage()
  public pagemode: any;

  @Input()
  public isClarification = false;

  @Input()
  public dontRenderNextJob = false;

  @Input()
  public assignedJob: any;

  @Input()
  public jobId: string = '';

  @Input()
  public previousJobId: string = '';

  public submitSubscription: Subscription = Subscription.EMPTY;
  public tenantInfos: any;
  public sidebarInfos: any;
  public showDuplicateListLink: boolean = false;
  public duplicateId: string = '';
  public isUpgrightDisplay: boolean = false;

  public newBankCustomerName: string = '';
  public oldBankCustomerName: string = '';

  @ViewChild('pdfViewer') pdfViewer: any;

  faShuffle = faShuffle;
  faSquareEnvelope = faSquareEnvelope;
  faEnvelopeOpen = faEnvelopeOpen;
  faTrash = faTrash;
  faCopy = faCopy;

  public numSiblings: number = 0;
  public numSiblingsOpen: number = 0;

  public rejectionReasonVisible: boolean = false;

  public errorMessage: string = '';

  public ibanTypeaheadOpenEvents = {
    newIban: new Subject<any>(),
    oldIban: new Subject<any>(),
  };

  public currentPage: number = 0;

  public documentTypeItems: any = [];

  get rejectionReasons() {
    return REJECTION_REASONS;
  }

  get courierBaseUrl() {
    return window.location.origin;
  }

  get rejectionReasonsGeneral() {
    return REJECTION_REASONS_GENERAL;
  }

  get rejectionReasonsDetailed() {
    return REJECTION_REASONS_DETAILED;
  }

  get hasRejectionReasonOther() {
    const { value } = this.form.controls['rejectionReason'];
    return (
      value != this.rejectionReasons.OK &&
      value != this.rejectionReasons.NEEDS_CLARIFICATION
    );
  }

  get ibansOnCurrentPage() {
    return this.job?.classificationByStephan?.suggestedIBANsPerPage[
      this.currentPage
    ];
  }

  get canSwitchIbans() {
    const { oldIban, newIban } = this.form.value;
    return !isEmpty(oldIban) || !isEmpty(newIban);
  }

  switchIbanValues() {
    const { oldIban, newIban } = this.form.value;
    this.form.patchValue({
      oldIban: newIban,
      newIban: oldIban,
    });
  }

  renderNextJob() {
    this.route.params.subscribe((r) => {
      if (r['id']) {
        this.router.navigate([Routes.IdentifyDocument]);
      } else {
        this.renderJob();
      }
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    const { assignedJob } = changes;
    if (assignedJob) {
      this.setModel(assignedJob.currentValue);
    }
  }

  get hasClosedStatus() {
    return this.job && this.job.status === 'closed';
  }

  ngOnInit(): void {
    this.route.params.subscribe((r) => {
      this.renderJob(r['id']);
    });

    this.navbarService.toggleUpright.subscribe((toggleUpright) => {
      this.isUpgrightDisplay = toggleUpright;
    });

    this.loadNextDocumentForm.valueChanges.subscribe((r) => {
      const { loadNext } = r;
      this.dontRenderNextJob = !loadNext;
    });

    this.documentTypeItems = this.identifyDocumentService.documentTypes;

    this.form
      .get('rejectionReason')
      ?.valueChanges.subscribe((rejectionReason) => {
        if (rejectionReason != 'OK') {
          this.setIbansAsRequired(false);
        } else {
          this.setIbansAsRequired(true);
        }
        this.form
          .get('rejectionReasonText')
          ?.setValue(
            this.translateService.instant(
              `rejectionReason.${rejectionReason}.text`,
            ),
          );
      });
  }

  openConfirmSendToBankModal() {
    this.ngxModalService
      .addModal(ApproveModalComponent, {
        title: 'captureTransaction.sendToBankModal.title',
        description: 'captureTransaction.sendToBankModal.description',
      })
      .subscribe((approved) => {
        if (approved) this.submitJob();
      });
  }

  pdfViewerLoaded($event: any) {
    this.pdfViewer.PDFViewerApplication.eventBus.on(
      'sidebarviewchanged',
      ($event: any) => {
        this.pagemode = $event.source.isOpen ? 'thumbs' : 'none';
      },
    );
  }

  public pdfViewerPageChange(count: number) {
    this.currentPage = count - 1;
  }

  get documentTypeForm() {
    return this.form.get('documentType') || new FormControl();
  }

  get tenant() {
    return this.job?.envelope.tenant;
  }

  get hasReviewReason() {
    return this.job?.reviewReason;
  }

  openDeleteModal() {
    this.ngxModalService
      .addModal(ApproveModalComponent, {
        title: 'identifyDocument.deleteModal.title',
        description: 'identifyDocument.deleteModal.description',
      })
      .subscribe((approved) => {
        if (approved) this.deleteJob();
      });
  }

  deleteJob() {
    this.identifyDocumentService.deleteSubscription =
      this.identifyDocumentService.deleteJob(this.job.id).subscribe((r) => {
        this.ngxModalService.removeAll();
        if (this.dontRenderNextJob) {
          this.noJobLeft = true;
          return;
        }
        this.renderNextJob();
      });
  }

  setRejectionReason(reason: string) {
    this.rejectionReasonVisible = true;
    this.form.get('rejectionReason')?.setValue(reason);
  }

  rejectionReasonTemplate(type: string) {
    if (type === 'mailInfo') {
      this.form.get('rejectionReasonText')?.setValue(
        this.form.get('rejectionReasonText')?.value +
          '\n' +
          this.translateService.instant(type, {
            name: this.tenantInfos?.bankName,
            mail: this.tenantInfos?.kwhEmail,
          }),
      );
      return;
    }
    this.setRejectionReason(type);
    this.form
      .get('rejectionReasonText')
      ?.setValue(this.translateService.instant(`rejectionReason.${type}.text`));
  }

  selectDocumentType(documentType: string) {
    this.form.patchValue({ documentType });
  }

  get documentType() {
    return this.form.value.documentType;
  }

  get rejectionReason() {
    return this.form.value.rejectionReason;
  }

  get documentTypeError() {
    return this.form.touched && this.form.controls['documentType']?.errors;
  }

  maybeSubmitJob() {
    const { rejectionReason } = this.form.controls;
    if (rejectionReason.value == this.rejectionReasons.NEEDS_CLARIFICATION) {
      return this.openConfirmSendToBankModal();
    }
    this.submitJob();
  }

  submitJob() {
    this.form.markAllAsTouched();

    const documntTypes = map(DOCUMENTTYPES, (i) => i.type);
    const selectedDocumentType = this.form.controls['documentType']?.value;
    if (!documntTypes.includes(selectedDocumentType)) {
      this.form.controls['documentType']?.setErrors({ required: true });
    }
    if (this.form.valid) {
      this.submitSubscription = this.identifyDocumentService
        .submitJob(this.job.id, this.form.value)
        .subscribe({
          next: () => {
            if (this.dontRenderNextJob) {
              this.noJobLeft = true;
              return;
            }
            this.renderNextJob();
          },
          error: (error: HttpErrorResponse) => {
            if (error.status === 409) {
              this.errorMessage = this.translateService.instant('error.409');
              this.showDuplicateListLink = true;
              this.duplicateId = error.error.duplicateId;
            }
            if (
              error.status === 404 &&
              error.error.errorMessage === 'NO_JOB_FOUND'
            ) {
              this.errorMessage =
                this.translateService.instant('error.no_job_found');
            } else if (error?.error?.message) {
              this.toastr.error(error.error.message);
            } else {
              this.toastr.error(error.message);
            }
          },
        });
    }
  }

  loadDuplicateList() {
    window.open(`/jobs/captureTransaction/${this.duplicateId}`, '_blank');
  }

  get customerDiffers() {
    return (
      !isEmpty(this.oldBankCustomerName) &&
      !isEmpty(this.newBankCustomerName) &&
      this.oldBankCustomerName != this.newBankCustomerName
    );
  }

  ibanSelected($event: any) {
    const type: string = $event.type;

    if (type === 'newIban') {
      this.newBankCustomerName = $event.customer;
    } else if (type === 'oldIban') {
      this.oldBankCustomerName = $event.customer;
    }

    if ($event.showCounterResults) {
      const counterType = type === 'newIban' ? 'oldIban' : 'newIban';
      (this.ibanTypeaheadOpenEvents as any)[counterType].next($event);
    }
  }

  setIbansAsRequired(yes = true) {
    this.form.get('newIban')?.setValidators(yes ? IBAN_VALIDATORS : []);
    this.form.get('newIban')?.updateValueAndValidity();
    this.form.get('oldIban')?.setValidators(yes ? IBAN_VALIDATORS : []);
    this.form.get('oldIban')?.updateValueAndValidity();
  }

  setModel(job: any) {
    this.navbarService.loadCounts.next('identifyDocument');
    this.noJobLeft = !job;

    if (this.noJobLeft) {
      return;
    }
    this.job = job;
    this.form.reset();

    this.errorMessage = '';
    this.newBankCustomerName = '';
    this.oldBankCustomerName = '';

    this.identifyDocumentService.queryBLZ(this.job.envelope.tenant).subscribe({
      next: (res) => {
        this.tenantInfos = res;
        this.sidebarInfos = merge(this.tenantInfos, this.job);
      },
      error: (res) => {
        // 404 tenant not found
        this.sidebarInfos = this.job;
      },
    });

    const {
      newIban,
      oldIban,
      documentType,
      rejectionReason,
      rejectionReasonText,
      tenant,
    } = this.job.envelope;

    this.form.setValue({
      newIban,
      oldIban,
      documentType,
      rejectionReason,
      rejectionReasonText,
      tenant,
    });

    this.form
      .get('rejectionReason')
      ?.setValue(rejectionReason ? rejectionReason : this.rejectionReasons.OK);
    this.form.get('rejectionReasonText')?.setValue(rejectionReasonText);

    if (!documentType && this.job.suggestedType) {
      this.form.get('documentType')?.setValue(this.job.suggestedType);
    }
    this.setIbansAsRequired(true);

    this.pdfUrl = '';
    setTimeout(() => {
      this.pdfUrl = escape(this.job.docUrl);
    }, 100);
  }

  renderJob(id: string = '') {
    if (this.assignedJob) {
      return;
    }
    this.identifyDocumentService
      .fetchNextJob(id)
      .subscribe((result: any) => this.setModel(result));
  }
}
