<div [class.columns]="!isUpgrightDisplay">
  <div [class.column]="!isUpgrightDisplay" class="is-3">
    <article class="panel is-primary">
      <p
        class="has-text-white panel-heading"
        translate="clarification.title"
      ></p>
      <div
        class="panel-body"
        [class.is-upright]="isUpgrightDisplay"
        [class.is-widescreen]="!isUpgrightDisplay"
      >
        <p
          *ngIf="clarificationJobs?.length == 0"
          class="ml-3 mt-4 has-text-weight-bold"
          translate="clarification.empty"
        ></p>
        <a
          *ngFor="let clarificationJob of clarificationJobs"
          class="panel-block flex is-flex-direction-column is-align-items-flex-start"
          [class.is-active]="clarificationJob.id === selectedJobId"
          (click)="selectJob(clarificationJob)"
        >
          <span class="panel-icon">
            <i class="fas fa-book" aria-hidden="true"></i>
          </span>

          <p>
            Tenant: <b>{{ clarificationJob.tenant }}</b>
          </p>
          <p>
            ID: <b>{{ clarificationJob.id }}</b>
          </p>
          <p>
            Previous Job ID: <b>{{ clarificationJob.previousJobId }}</b>
          </p>

          <p>
            Geändert von:
            <span class="tag is-primary has-text-weight-bold">{{
              clarificationJob.statusChangedBy
            }}</span>
          </p>

          <p>
            <b>{{ clarificationJob.statusChangedAt | date: "medium" }}</b>
          </p>

          <p class="box is-fullwidth">
            {{ clarificationJob.reviewReason }}
          </p>
        </a>
      </div>
    </article>
  </div>
  <div class="column">
    <div *ngIf="selectedJob">
      <app-identify-document
        [dontRenderNextJob]="true"
        [isClarification]="true"
        [assignedJob]="selectedJob"
        [jobId]="selectedJobId"
        [previousJobId]="selectedJobPreviousJobId"
      ></app-identify-document>
    </div>
  </div>
</div>
