<div class="columns" *ngIf="!noJobLeft">
  <div
    class="column pdf-container darkmode-ignore"
    [class.is-6]="isUpgrightDisplay"
    [class.is-8]="!isUpgrightDisplay"
  >
    <ng2-pdfjs-viewer
      *ngIf="pdfUrl"
      #pdfViewer
      viewerId="JobPdfViewer"
      [pdfSrc]="pdfUrl"
      (onDocumentLoad)="pdfViewerLoaded($event)"
      [pagemode]="pagemode"
      (onPageChange)="pdfViewerPageChange($event)"
    ></ng2-pdfjs-viewer>

    <div class="flex">
      <div *ngIf="isClarification" class="box is-fullwidth">
        <b>PreviousJobID: </b> {{ previousJobId }} <br />
        <b>ID: </b> {{ jobId }}<br />
        <b>Courier Link: </b>
        <a href="{{ courierBaseUrl }}/jobs/captureTransaction/{{ jobId }}"
          >{{ courierBaseUrl }}/jobs/captureTransaction/{{ jobId }}</a
        >
        <br />
        <span *ngIf="job.envelope.newIban">
          <b>New Iban: </b> {{ job.envelope.newIban | iban }}
        </span>
      </div>
    </div>
  </div>

  <form class="column" [formGroup]="form">
    <app-siblings [jobId]="job?.id"></app-siblings>

    <div
      *ngIf="hasClosedStatus"
      class="notification is-warning"
      translate="identifyDocument.closed"
    ></div>

    <div *ngIf="hasReviewReason" class="notification is-danger">
      {{ job.reviewReason }}
    </div>

    <div class="columns is-gapless is-multiline mb-1">
      <div class="column is-full-widescreen is-half-fullhd">
        <b class="has-text-primary">{{ tenantInfos?.bankName || tenant }}</b>
        <span class="ml-1">( {{ tenantInfos?.blz }} )</span>
      </div>
      <div class="column is-flex">
        <fa-icon class="mr-2" [icon]="faSquareEnvelope"></fa-icon
        ><span>{{ tenantInfos?.kwhEmail }}</span>
      </div>
    </div>

    <div>
      @for (documentTypes of documentTypeItems; track $index; let i = $index) {
        @for (type of documentTypes; track type.id) {
          <span
            class="tag is-size-6 m-2 has-click has-text-weight-bold"
            [class.is-primary]="type.value == documentType"
            [class.is-danger]="documentTypeError"
            (click)="selectDocumentType(type.value)"
            >{{ type.label | translate }}
          </span>
        }
        <div *ngIf="i == 0" class="divider m-0"></div>
      }
    </div>

    <div class="is-flex is-flex-direction-column ml-2 iban-container">
      @for (iban of ibansOnCurrentPage; track iban.id) {
        <button
          class="tag is-black has-click mb-2 is-medium is-size-6"
          ngxClipboard
          fino-button
          [cbContent]="iban"
        >
          <span [innerHtml]="iban | ibanColor: tenantInfos?.blz"></span>
          <fa-icon [icon]="faCopy" class="has-text-white ml-3"></fa-icon>
        </button>
      }
    </div>

    <div class="columns my-0 pl-2">
      <div class="column is-10">
        <div>
          <b [class.has-text-danger]="customerDiffers"
            ><span translate="identifyDocument.customer"></span>:
            {{ oldBankCustomerName }}</b
          >
          <app-iban-typeahead
            name="oldIban"
            [openTypeahead]="ibanTypeaheadOpenEvents.oldIban"
            (ibanSelected)="ibanSelected($event)"
            [form]="form"
            [tenant]="tenant"
          ></app-iban-typeahead>
        </div>
        <div class="pt-1 mb-4">
          <b [class.has-text-danger]="customerDiffers"
            ><span translate="identifyDocument.customer"></span>:
            {{ newBankCustomerName }}</b
          >
          <app-iban-typeahead
            name="newIban"
            [openTypeahead]="ibanTypeaheadOpenEvents.newIban"
            (ibanSelected)="ibanSelected($event)"
            [form]="form"
            [tenant]="tenant"
          ></app-iban-typeahead>
        </div>
      </div>
      <div
        class="column is-flex is-justify-content-center is-align-items-center"
      >
        <button
          class="mb-4"
          type="button"
          (click)="switchIbanValues()"
          fino-button
          [disabled]="!canSwitchIbans"
        >
          <fa-icon [icon]="faShuffle"></fa-icon>
        </button>
      </div>
    </div>

    <div class="column is-flex py-0">
      @for (reason of rejectionReasonsGeneral; track reason.id) {
        <button
          class="mr-2 mt-2"
          fino-button
          (click)="setRejectionReason(reason.value)"
          [primary]="reason.higlightedBy.includes(rejectionReason)"
        >
          @if (!isClarification) {
            <fa-icon size="2x" [icon]="reason.icon"></fa-icon>
          }
          <span [class.ml-2]="!isClarification">{{
            reason.translation | translate
          }}</span>
        </button>
      }

      <button
        class="mt-2"
        type="button"
        (click)="openDeleteModal()"
        fino-button
      >
        @if (!isClarification) {
          <fa-icon size="2x" [icon]="faTrash"></fa-icon>
        }
        <span
          [class.ml-2]="!isClarification"
          translate="identifyDocument.deleteModal.title"
        ></span>
      </button>
    </div>

    <div class="is-flex mb-2">
      <div class="column is-flex is-flex-wrap-wrap">
        <button
          class="mr-2 mt-2"
          [attr.data-tooltip]="
            'identifyDocument.rejectionReason.tooltip' | translate
          "
          fino-button
          (click)="rejectionReasonTemplate('mailInfo')"
        >
          <fa-icon size="lg" [icon]="faEnvelopeOpen"></fa-icon>
        </button>
        @for (reason of rejectionReasonsDetailed; track reason.id) {
          <button
            class="mr-2 mt-2"
            [disabled]="!hasRejectionReasonOther"
            fino-button
            [primary]="rejectionReason === reason && hasRejectionReasonOther"
            (click)="rejectionReasonTemplate(reason)"
          >
            {{ reason | translate }}
          </button>
        }
      </div>
    </div>

    <div class="pl-4">
      <fino-textarea
        *ngIf="rejectionReasonVisible"
        formControlName="rejectionReasonText"
        label="rejectionReasonText"
      >
      </fino-textarea>

      <article *ngIf="errorMessage" class="message is-danger">
        <div class="message-header">
          <p>Fehler</p>
          <button class="delete" aria-label="delete"></button>
        </div>
        <div class="message-body">
          {{ errorMessage }}
        </div>
      </article>

      <form *ngIf="!isClarification" [formGroup]="loadNextDocumentForm">
        <fino-checkbox
          formControlName="loadNext"
          label="identifyDocument.loadNext"
        >
        </fino-checkbox>
      </form>

      <div class="columns">
        <div class="column">
          <button
            class="is-fullwidth"
            [primary]="!showDuplicateListLink"
            type="submit"
            (click)="maybeSubmitJob()"
            [loading]="!submitSubscription.closed"
            fino-button
            translate="identifyDocument.save"
          ></button>
        </div>

        <div class="column" *ngIf="showDuplicateListLink">
          <button
            class="is-fullwidth"
            [primary]="true"
            type="button"
            (click)="loadDuplicateList()"
            fino-button
            translate="identifyDocument.openDuplicate"
          ></button>
        </div>
      </div>
    </div>

    <app-tenant-infos [infos]="sidebarInfos"></app-tenant-infos>
  </form>
</div>

<div
  class="is-flex is-justify-content-center is-align-items-center is-fullheight"
  *ngIf="noJobLeft"
>
  <h5 class="title is-size-5" translate="identifyDocument.empty"></h5>
</div>
