<table class="table mt-4">
  <thead>
    <tr>
      <th class="tenant-column" translate>ticketOverview.tenant</th>
      <th class="type-column">
        <div class="is-flex">
          <p translate>ticketOverview.incoming</p>
          / <br />
          <p translate>ticketOverview.outgoing</p>
        </div>
      </th>
      <th class="created-column">
        <div class="is-flex">
          <p translate>ticketOverview.created</p>
          <app-sort-icon
            (onClick)="sortByField($event)"
            [sortOrder]="sortOrder"
            [currentFilter]="filterProperty"
            property="created"
            class="ml-2"
          ></app-sort-icon>
        </div>
      </th>
      <th class="client-column">
        <div class="is-flex">
          <p translate>ticketOverview.customer</p>
          <app-sort-icon
            (onClick)="sortByField($event)"
            [sortOrder]="sortOrder"
            [currentFilter]="filterProperty"
            property="client"
            class="ml-2"
          ></app-sort-icon>
        </div>
      </th>
      <th class="next-deadline-column">
        <div class="is-flex">
          <p translate>ticketOverview.deadline</p>
          <app-sort-icon
            (onClick)="sortByField($event)"
            [currentFilter]="filterProperty"
            [sortOrder]="sortOrder"
            property="nextDeadline"
            class="ml-2"
          ></app-sort-icon>
        </div>
      </th>
      <th class="status-column">
        <div class="is-flex">
          <p translate>ticketOverview.status</p>
          <app-sort-icon
            (onClick)="sortByField($event)"
            [currentFilter]="filterProperty"
            [sortOrder]="sortOrder"
            property="status"
            class="ml-2"
          ></app-sort-icon>
        </div>
      </th>
      <th class="in-charge-column">
        <div class="is-flex">
          <p translate>ticketOverview.lockedBy</p>
          <app-sort-icon
            (onClick)="sortByField($event)"
            [currentFilter]="filterProperty"
            [sortOrder]="sortOrder"
            property="lockedBy"
            class="ml-2"
          ></app-sort-icon>
        </div>
      </th>
      <th class="icons-column">
        <div class="is-flex"></div>
      </th>
    </tr>
  </thead>
  <tbody *ngIf="!isLoading">
    <tr
      *ngFor="let ticket of tickets; let i = index"
      [class.disabled]="ticket.lockedBy"
      (click)="openTicket(ticket)"
    >
      <td>{{ ticket.tenant }}</td>
      <td>{{ ticket.type | translate }}</td>
      <td>{{ ticket.created | date }}</td>
      <td>{{ ticket.client }}</td>
      <td>{{ ticket.nextDeadline | date }}</td>
      <td>
        <span
          class="ticket-label"
          [style.backgroundColor]="getTicketColor(ticket.status)"
        >
          {{ "app.ticket.status." + ticket.status | translate }}
        </span>
      </td>
      <td>
        <span *ngIf="!ticket.lockedBy">-</span>
        <div *ngIf="ticket.lockedBy">
          {{ ticket.lockedBy }}
          <span *ngIf="ticket.lockedAt">{{
            ticket.lockedAt | date: "dd.MM.yyyy - HH:mm"
          }}</span>
        </div>
      </td>
      <td>
        <fa-icon
          *ngIf="ticket.remindersPaused"
          [attr.data-tooltip]="'ticketOverview.remindersPaused' | translate"
          [icon]="faBell"
        ></fa-icon>
      </td>
    </tr>
  </tbody>
</table>
