import { Component, OnInit, signal } from '@angular/core';

//@ts-ignore
import Darkmode from 'darkmode-js';
import { LocalStorageService } from 'ngx-webstorage';
import { SentryErrorService } from './core/error/sentry-error.service';
import { AppService } from './app.service';
import { NavbarComponent } from './components/navbar/navbar.component';
import { FreshdeskComponent } from './components/freshdesk/freshdesk.component';
import { RouterOutlet } from '@angular/router';
import { TranslateService } from '@fino-ui/translation';
import { NgxLoadingModule } from 'ngx-loading';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.sass'],
  imports: [
    NavbarComponent,
    FreshdeskComponent,
    RouterOutlet,
    NgxLoadingModule,
  ],
})
export class AppComponent implements OnInit {
  constructor(
    private storage: LocalStorageService,
    private translateService: TranslateService,
    private sentryErrorService: SentryErrorService,
    private appService: AppService,
  ) {
    const darkmode = new Darkmode({
      bottom: '15px',
      right: 'unset',
      left: '160px',
      time: '0.5s',
      label: '🌓',
    });
    darkmode.showWidget();
  }
  title = 'x2-courier-ui';

  loadingSpinnerConfig = {
    primaryColour: '#000',
    backdropBorderRadius: '3px',
  };

  get loading() {
    return this.appService.loading;
  }

  ngOnInit(): void {
    const lang = this.storage.retrieve('language');
    if (lang) {
      this.translateService.use(lang);
    }

    this.sentryErrorService.initSentry();
  }
}
