<form [formGroup]="form">
  <app-typeahead
    formControlName="creditorId"
    [form]="form"
    (searchChanged)="creditorIdSearchChanged()"
    [resultSelected]="directDebitSelected"
    (keyboardSelected)="keyboardSelectedCreditor($event)"
    [canNavigateByKeys]="true"
    [loading]="creditorIdLoading"
    [results]="creditorIdResults"
    [resultTemplate]="creditorItemTpl"
    ngDefaultControl
  ></app-typeahead>

  @if (creditorIdMayInvalid) {
    <div class="notification is-warning" translate="warnings.creditorId"></div>
  }

  <app-typeahead
    formControlName="name"
    [form]="form"
    (searchChanged)="nameSearchChanged()"
    [loading]="creditorNameLoading"
    [resultSelected]="directDebitSelected"
    [canNavigateByKeys]="true"
    (keyboardSelected)="keyboardSelectedName($event)"
    [results]="creditorNameResults"
    [resultTemplate]="creditorItemTpl"
    ngDefaultControl
  ></app-typeahead>

  <ng-template #creditorItemTpl let-item>
    @if (item) {
      <div
        [class.has-click]="!item.closed"
        class="is-flex is-justify-content-center is-align-items-center is-flex-direction-column"
        (click)="handleCreditorResultSelected(item)"
      >
        <div class="is-flex">
          @if (isIgnorableCreditor(item)) {
            <fa-icon [icon]="faTimes" class="has-text-danger mr-2"></fa-icon>
          }

          <strong class="is-size-6">{{ item.name }}</strong> <br />
        </div>
        <div class="has-text-grey is-size-6">
          <div>
            <small>{{ (item.creditorId | creditor) || "-" }}</small>
            <br />
          </div>
        </div>
      </div>
    }
  </ng-template>

  @if (isMaybeOneTimeDirectDebit) {
    <div
      class="notification is-warning"
      translate="warnings.maybeOneTimeDirectDebit"
    ></div>
  }

  <fino-input formControlName="purpose" label="purpose">
    <fino-validation-indicator></fino-validation-indicator>
  </fino-input>

  <fino-input formControlName="mref" label="mref">
    <fino-validation-indicator></fino-validation-indicator>
  </fino-input>

  <button
    type="submit"
    fino-button
    (click)="submit()"
    [disabled]="!isFormValid"
    [primary]="true"
    class="mb-3"
  >
    @if (isUpdateMode) {
      <span translate="directDebits.update"> </span>
    } @else {
      <span translate="directDebits.add"> </span>
    }
  </button>

  @if (hasDirectDebits) {
    <div class="columns" [formGroup]="searchForm">
      <div class="column is-8">
        <fino-input formControlName="search" class="mr-2" label="Suchen">
        </fino-input>
      </div>
      <div class="column">
        <button
          class="mt-1 is-fullwidth is-medium"
          fino-button
          (click)="deleteAllItems()"
        >
          <label class="mr-1" translate="directDebits.all"></label>
          <fa-icon [icon]="faTrash"></fa-icon>
        </button>
      </div>
    </div>
  }

  @if (hasDirectDebits) {
    <div class="items-container">
      @for (directDebit of filteredDirectDebits; track directDebit.id) {
        <article [class.is-primary]="directDebit.selected" class="panel">
          <div class="panel-heading">
            <div class="columns">
              <div class="column">
                <p class="has-text-white">{{ directDebit.name }}</p>
              </div>
              <div class="column is-flex is-justify-content-flex-end">
                <fa-icon
                  [icon]="faEdit"
                  (click)="editItem(directDebit)"
                  class="has-text-white mr-3"
                ></fa-icon>
                <fa-icon
                  [icon]="faCopy"
                  (click)="duplicateItem(directDebit)"
                  class="has-text-white mr-3"
                ></fa-icon>
                <fa-icon
                  [icon]="faTrash"
                  (click)="deleteItem(directDebit, !directDebit.selected)"
                  class="has-text-white"
                ></fa-icon>
              </div>
            </div>
          </div>

          <div
            class="panel-block is-flex is-flex-direction-column is-align-items-flex-start"
            [class.has-text-grey-light]="!directDebit.selected"
          >
            <label
              ><span translate="directDebits.creditorId"></span>
              <b>{{ directDebit.creditorId }}</b>
            </label>

            <label
              ><span translate="directDebits.mref"></span>
              <b>{{ directDebit.mref }}</b></label
            >

            <label
              ><span translate="directDebits.purpose"></span>
              <b>{{ directDebit.purpose }}</b></label
            >
          </div>
        </article>
      }
    </div>
  }
</form>
