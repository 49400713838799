<div *ngIf="numSiblings" class="notification is-warning">
  <div
    [innerHtml]="
      'identifyDocument.siblings'
        | translate
          : {
              numSiblings: numSiblings,
              numSiblingsOpen: numSiblingsOpen
            }
    "
  ></div>

  <div>
    {{ jobId }}
  </div>

  <button
    class="mt-2"
    type="button"
    (click)="mergeSiblings()"
    fino-button
    translate="identifyDocument.mergeDocuments"
  ></button>
</div>
